import { initialState } from '../../../constants/steps-config';

const initialStateForReducer = {
  data: initialState,
};

const projectAllocationReducer = (state = initialStateForReducer, action) => {
  switch (action.type) {
    case 'SET_PROJECT_ALLOCATION':
      return { ...state, ...action.payload.data };
    default:
      return state;
  }
};

export default projectAllocationReducer;
