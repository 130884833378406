import React from 'react';
import { StyleSheet, Text, View } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  section: {
    fontSize: 10,
    paddingLeft: 7,
    color: '#8c8c8c',
  },
});

export function DocumentationUploadSectionDescription() {
  const { section } = styles;
  return (
    <View style={section}>
      <Text>
        It is recommended that you provide documentation to support employee involvement in the
        projects reported in the survey. Retaining documentation to support the reported is
        extremely important. Generally, it is necessary to retain documentation for at least seven
        years. Please keep copies of all the documents included on this list in secure storage.
        Based on your responses, the Tax Department may contact you in the future to obtain
        additional documentation.
      </Text>
    </View>
  );
}

export default DocumentationUploadSectionDescription;
