import { createAction } from 'redux-actions';

export const updateSecondaryAssigneesAction = createAction('UPDATE_SECONDARY_ASSIGNEES');

const initialState = [];
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case 'UPDATE_SECONDARY_ASSIGNEES':
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};
