import React from 'react';
import { Button, SpaceBetween } from '@amzn/awsui-components-react';
import PropTypes from 'prop-types';
import PhoneToolTiles from '../Employee/components/PhoneToolTiles';

const ClickablePhoneToolIcon = ({
  formattedEmployeeName,
  employeeAlias,
  jobTitle,
  index,
  onCancel,
  phoneToolURL,
  badgePhotoURL,
  isReadOnly,
}) => {
  const onClickHandler = () => {
    onCancel(index);
  };

  return (
    <SpaceBetween size="m" direction="horizontal">
      <PhoneToolTiles
        formattedEmployeeName={formattedEmployeeName}
        employeeAlias={employeeAlias}
        jobTitle={jobTitle}
        phoneToolURL={phoneToolURL}
        badgePhotoURL={badgePhotoURL}
      />
      <Button iconName="close" variant="icon" onClick={onClickHandler} disabled={isReadOnly} />
    </SpaceBetween>
  );
};

ClickablePhoneToolIcon.propTypes = {
  formattedEmployeeName: PropTypes.string.isRequired,
  employeeAlias: PropTypes.string.isRequired,
  jobTitle: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  phoneToolURL: PropTypes.string.isRequired,
  badgePhotoURL: PropTypes.string.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
};

export default ClickablePhoneToolIcon;
