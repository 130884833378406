import { Box, Cards } from '@amzn/awsui-components-react';
import React from 'react';
import PropTypes from 'prop-types';

const MultiSelectCards = ({ list, isVisible }) => {
  if (isVisible) {
    return (
      <Cards
        items={list}
        trackBy="name"
        selectionType="multi"
        loadingText="Loading projects"
        selectedItems={list}
        cardsPerRow={[{ cards: 1 }]}
        isItemDisabled={() => true}
        cardDefinition={{
          header: e => e.name,
          sections: [
            {
              id: 'description',
              content: e => e.description,
            },
          ],
        }}
        empty={
          <Box textAlign="center" color="inherit">
            <Box padding={{ bottom: 's' }} variant="p" color="inherit">
              No activities to display.
            </Box>
          </Box>
        }
      />
    );
  }
  return null;
};
MultiSelectCards.propTypes = {
  list: PropTypes.array.isRequired,
  isVisible: PropTypes.bool.isRequired,
};
export default MultiSelectCards;
