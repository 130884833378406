import { initialState } from '../../../constants/steps-config';

const initialStateForReducer = {
  ...initialState.ActivityDualAllocation,
};

const activityDualAllocationReducer = (state = initialStateForReducer, action) => {
  switch (action.type) {
    case 'SET_ACTIVITY_DUAL_ALLOCATION':
      return { ...state, ...action.payload.data };
    default:
      return state;
  }
};

export default activityDualAllocationReducer;
