import React from 'react';
import { get, isNull } from 'lodash';
import PropTypes from 'prop-types';
import { AllocationMatrix } from '../../../common/components/AllocationMatrix';
import getProjectMapping from '../../../../utils/ProjectUtils';

export function VendorProjectAllocationMatrix({ data }) {
  const projectAllocationData = get(data, 'userResponse.ProjectAllocation.data', {});
  const { searchProjectsResponse } = data;
  const selectedProjects = get(data, 'userResponse.Project.data.selectedProjects', []);
  const projectMapping = getProjectMapping(selectedProjects, searchProjectsResponse);
  const vendorMapping = {};
  const vendorData = get(data, 'vendorData', []);
  if (!isNull(vendorData)) {
    vendorData.forEach(vendor => {
      const vendorId = get(vendor, 'recordId', '');
      vendorMapping[vendorId] =
        `${get(vendor, 'vendorName', '')}\n${get(vendor, 'costCenterNumber', '')}\n` +
        `$${get(vendor, 'amount', '').toLocaleString('en-US')}`;
    });
  }
  return (
    <AllocationMatrix
      data={projectAllocationData}
      projectActivityNameMapping={projectMapping}
      entityNameMapping={vendorMapping}
    />
  );
}

VendorProjectAllocationMatrix.propTypes = {
  data: PropTypes.object.isRequired,
};

export default VendorProjectAllocationMatrix;
