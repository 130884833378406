import React from 'react';
import { View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import { Heading } from '../../../../../components/Heading';
import { SurveyVendorSection } from './components/SurveyVendorSection';
import { SurveySecondaryAssigneeSection } from './components/SurveySecondaryAssigneeSection';

export function SurveyVendorListSection({ data }) {
  return (
    <View>
      <Heading name="Supplier" />
      <SurveyVendorSection data={data} />
      <SurveySecondaryAssigneeSection data={data} />
    </View>
  );
}

SurveyVendorListSection.propTypes = {
  data: PropTypes.object.isRequired,
};

export default SurveyVendorListSection;
