import React, { useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Button,
  ColumnLayout,
  ExpandableSection,
  Modal,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import { forEach, get, map, isEmpty, floor, filter } from 'lodash';
import PropTypes from 'prop-types';
import { keys } from 'lodash/fp/_util';
import { round, sum } from 'lodash/math';
import { values } from 'lodash/object';
import { connect, useDispatch } from 'react-redux';
import * as XLSX from 'xlsx';
import AllocationGrid from '../../AllocationGrid/AllocationGrid';
import { getEntityData, getSurveyEntity } from '../../../../../common/constants/surveyType';
import { validationInitialState } from '../../../constants/steps-config';
import { setActivityAllocation as setActivityAllocationAction } from '../../../redux/actions/activityAllocationActions';
import { isReadOnly } from '../../../utils/survey_page_utils';
import FillMatrixWithExcel from '../../AllocationGrid/FillMatrixWithExcel';
import { setCopyGridValues as setCopyGridValuesAction } from '../../../redux/actions/copyGridValuesActions';

const ActivityAllocation = ({
  state,
  setState,
  pageContents,
  validationState,
  setValidationState,
  onNavigate,
  surveyType,
  surveyDetails,
  activityAllocation,
  copyGridValues,
}) => {
  const dispatch = useDispatch();
  const entityData = getEntityData(pageContents, surveyType);
  const DefaultActivities = get(state, 'Activity.data.defaultItems', []);
  const selectedActivities = get(state, 'Activity.data.selectedActivities', []);
  const activityRemoved = get(validationState, 'activityAllocationState.activityRemoved', false);
  const [excelUploadModal, setExcelUploadModal] = useState(false);

  const setCopyGridValues = (value, column) => {
    dispatch(
      setCopyGridValuesAction({
        ...copyGridValues,
        activityCopiedValues: value,
        activityCopiedColumn: column,
      }),
    );
  };

  const validateActivityAllocation = activity => {
    const entityList = entityData;
    if (isEmpty(activity) || Object.keys(activity).length !== entityList.length) return false;
    let validation = true;
    Object.keys(activity).forEach(entity => {
      if (Number(activity[entity].total) !== 100) {
        validation = false;
      }
    });

    return validation;
  };

  const setActivityAllocation = val => {
    dispatch(
      setActivityAllocationAction({
        ...val,
        error: !validateActivityAllocation(val?.data),
        errorMessage: null,
      }),
    );
  };

  useEffect(() => {
    const oldState = activityAllocation.data || {};
    const Activities = map([...DefaultActivities, ...selectedActivities], ob => ob.id);
    const newState = {};

    forEach(keys(oldState), col => {
      const x = {};
      forEach(Activities, row => {
        if (oldState[col][row] && oldState[col][row] !== '0') {
          x[row] = oldState[col][row];
        }
      });
      x.total = sum(map(values(x), val => round(parseFloat(val), 1)))
        .toFixed(1)
        .toString();
      newState[col] = x;
    });
    setActivityAllocation({
      ...activityAllocation,
      data: newState,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(selectedActivities)]);

  const activities = [...DefaultActivities, ...selectedActivities];

  const onAllocationChange = (val, rowId, colId) => {
    if (Number.isNaN(Number(val))) return;
    let value = val || '0';
    if (Number(value) < 0) return;
    value = round(Number(value), 1);
    const prevVal =
      (activityAllocation.data[colId] && activityAllocation.data[colId][rowId]) || '0';
    const prevTotal =
      (activityAllocation.data[colId] && activityAllocation.data[colId].total) || '0';
    let newTotal = floor(parseFloat(prevTotal), 1) - round(parseFloat(prevVal), 1) + value;
    newTotal = newTotal.toFixed(1);
    const newData = {
      ...activityAllocation.data,
      [colId]: {
        ...activityAllocation.data[colId],
        [rowId]: val,
        total: newTotal,
      },
    };

    setActivityAllocation({
      ...activityAllocation,
      data: newData,
      error: !validateActivityAllocation(newData),
    });
  };

  const uploadedData = importedData => {
    if (importedData.status === 'error') {
      setActivityAllocation({
        ...activityAllocation,
        errorMessage: importedData.errorMessage,
      });
    } else {
      setActivityAllocation({
        ...activityAllocation,
        data: importedData,
        error: !validateActivityAllocation(importedData),
        errorMessage: null,
      });
    }
  };

  const updateActiveIndex = () => {
    setState({
      key: 'activeStep',
      value: 1,
    });
  };
  const closeNoAllocationModal = () => {
    setValidationState(validationInitialState);
  };
  useEffect(() => {
    if (activityRemoved) {
      setValidationState(validationInitialState);
      const detail = {
        requestedStepIndex: state.activeStep + 1,
      };
      onNavigate({ detail });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DefaultActivities, selectedActivities, activityRemoved]);
  const removeActivityFromSelectedList = () => {
    const defaultActivities = get(state, 'Activity.data.defaultItems', {});
    const selectedActivity = get(state, 'Activity.data.selectedActivities', {});
    const removeActivityId = validationState.activityAllocationState.activityId;
    const currentDefaultActivity = filter(defaultActivities, activity => {
      return activity.id !== removeActivityId;
    });
    const currentSelectedActivity = filter(selectedActivity, activity => {
      return activity.id !== removeActivityId;
    });
    setState({
      key: 'Activity',
      value: {
        ...state.Activity,
        data: {
          ...get(state, 'Activity.data', {}),
          defaultItems: currentDefaultActivity,
          selectedActivities: currentSelectedActivity,
        },
      },
    });
    setValidationState(prevState => {
      return {
        ...prevState,
        activityAllocationState: {
          ...prevState.activityAllocationState,
          activityRemoved: true,
        },
      };
    });
  };

  const { error, errorMessage } = activityAllocation;

  const exportActivityAllocationToExcel = (activityData, activityList) => {
    const workbook = XLSX.utils.book_new();

    const worksheetData = [];
    const headerRow = ['Activities', ...(entityData || []).map(employee => employee.id)];
    worksheetData.push(headerRow);

    activityList.forEach(activity => {
      const rowData = [activity.name];
      (entityData || []).forEach(employee => {
        const cellValue = activityData?.[employee.id]?.[activity.id] || '';
        rowData.push(cellValue);
      });
      worksheetData.push(rowData);
    });

    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'ActivityAllocation');

    XLSX.writeFile(workbook, 'activity-allocation.xlsx');
  };

  const handleExportClick = () => {
    const activityData = activityAllocation.data;
    const activityList = activities;

    exportActivityAllocationToExcel(activityData, activityList);
  };

  const getActivityIdByName = activityName => {
    // eslint-disable-next-line no-shadow
    const activityItem = activities.find(activityItem => activityItem.name === activityName);
    return activityItem ? activityItem.id : null;
  };

  const parseExcelForActivityDualAllocation = file => {
    const reader = new FileReader();

    reader.onload = function parseExcelOnLoad(e) {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });

      const gridData = {};

      const worksheet = workbook.Sheets.ActivityAllocation;
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      const [headerRow, ...dataRows] = jsonData;

      dataRows.forEach(row => {
        const activityName = row[0];
        const activityId = getActivityIdByName(activityName);

        if (activityId) {
          headerRow.slice(1).forEach((employeeId, index) => {
            const cellValue = row[index + 1] !== undefined ? String(row[index + 1]) : '0';

            if (cellValue !== '' && parseFloat(cellValue) !== 0) {
              if (!gridData[employeeId]) {
                gridData[employeeId] = {};
              }
              gridData[employeeId][activityId] = cellValue;
            }
          });
        }
      });

      Object.keys(gridData).forEach(employeeId => {
        gridData[employeeId].total = Object.values(gridData[employeeId])
          .reduce((totalSum, val) => totalSum + parseFloat(String(val) || '0'), 0)
          .toFixed(1);
      });

      setActivityAllocation({
        ...activityAllocation,
        data: gridData,
        error: !validateActivityAllocation(gridData),
        errorMessage: null,
      });
    };

    reader.readAsBinaryString(file);
  };

  const handleFileSelected = file => {
    if (file) {
      parseExcelForActivityDualAllocation(file);
    }
  };

  return (
    <SpaceBetween size="xs">
      {process.env.REACT_APP_COUNTRY === 'gbr' && (
        <ExpandableSection headerText="Activity definition" variant="container">
          <ColumnLayout columns={2}>
            <div>
              <Box variant="awsui-key-label">Qualifying activities</Box>
              <ul>
                <li>Activities needed to resolve the scientific or technological uncertainty.</li>
                <li>Design, coding, testing and analysis.</li>
                <li>Designing, constructing and testing prototypes / proof of concepts (POCs).</li>
              </ul>
              <Box variant="awsui-key-label">Non-qualifying activities</Box>
              <ul>
                <li>
                  Routine / business as usual activities that do not contribute to resolving the
                  technological or scientific uncertainties.
                </li>
              </ul>
            </div>
            <div>
              <Box variant="awsui-key-label">Supervision & support</Box>
              <ul>
                <li>
                  Project planning, managing and directing the R&D activities - defining the
                  technological objectives, assessing technological feasibility, estimating
                  development time. Includes including resource planning, project reviews, project
                  budgeting.
                </li>
              </ul>
              <Box variant="awsui-key-label">General administrative</Box>
              <ul>
                <li>
                  Time spent on activities such as team meetings, appraisals, budgeting, training,
                  time reporting.
                </li>
              </ul>
              <Box variant="awsui-key-label">KTLO / On-Call / other commercial activities</Box>
              <ul>
                <li>
                  Time spent on non-project market research, non-technical business development.
                </li>
              </ul>
            </div>
          </ColumnLayout>
        </ExpandableSection>
      )}

      {error && (
        <Alert visible="true" dismissAriaLabel="Close alert" type="error">
          You must allocate a total of 100% to each {getSurveyEntity(surveyType)}
        </Alert>
      )}

      {errorMessage && (
        <Alert visible="true" dismissAriaLabel="Close alert" type="error">
          {errorMessage}
        </Alert>
      )}

      <Box float="right">
        <Button onClick={() => setExcelUploadModal(true)} disabled={isReadOnly(surveyDetails)}>
          Fill matrix with Excel
        </Button>
      </Box>

      <FillMatrixWithExcel
        isVisible={excelUploadModal}
        setModalVisible={setExcelUploadModal}
        onDownloadTemplate={() => handleExportClick()}
        onFillMatrix={handleFileSelected}
      />

      <AllocationGrid
        titleEnitity="activities"
        title="Activity Matrix"
        colsEntity={entityData}
        rowsEntity={activities}
        gridValues={activityAllocation?.data}
        setGridValues={val => {
          setActivityAllocation({
            ...activityAllocation,
            data: val,
          });
        }}
        onAllocationChange={onAllocationChange}
        uploadedData={uploadedData}
        selectEntity={updateActiveIndex}
        allocationType="Activity"
        surveyType={surveyType}
        surveyDetails={surveyDetails}
        copiedValues={copyGridValues.activityCopiedValues}
        copiedColumn={copyGridValues.activityCopiedColumn}
        setCopiedValues={setCopyGridValues}
      />
      <Modal
        onDismiss={closeNoAllocationModal}
        visible={validationState.activityAllocationState.modalVisible}
        closeAriaLabel="Close modal"
        size="medium"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="normal" onClick={closeNoAllocationModal}>
                Edit allocation
              </Button>
              <Button variant="primary" onClick={removeActivityFromSelectedList}>
                Proceed
              </Button>
            </SpaceBetween>
          </Box>
        }
        header="Activity has no time allocation"
      >
        <div>
          You didn&apos;t allocate any of the employee&apos;s time to activity{' '}
          <b>{validationState.activityAllocationState.activityName}</b>. If you choose to proceed,
          this activity will be removed from your activity list.
        </div>
      </Modal>
    </SpaceBetween>
  );
};

ActivityAllocation.propTypes = {
  state: PropTypes.object.isRequired,
  setState: PropTypes.func.isRequired,
  pageContents: PropTypes.object.isRequired,
  surveyType: PropTypes.string.isRequired,
  validationState: PropTypes.object.isRequired,
  setValidationState: PropTypes.func.isRequired,
  onNavigate: PropTypes.func.isRequired,
  surveyDetails: PropTypes.object.isRequired,
  activityAllocation: PropTypes.object.isRequired,
  copyGridValues: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  activityAllocation: get(state, 'activityAllocation', {}),
  copyGridValues: get(state, 'copyGridValues', {}),
});
export default connect(mapStateToProps)(ActivityAllocation);
