import React, { useEffect } from 'react';
import { Alert, Box, Grid, SpaceBetween } from '@amzn/awsui-components-react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash/lang';
import SurveySubmittedAlert from '../../common/components/survey_submitted_alert/SurveySubmittedAlert';
import { setSurveysActon } from '../../common/redux/GetSurveys';
import { getSurveyDetailsAction } from '../../common/redux/GetSurveyDetails';
import getSurveyDetailsList from '../../common/utils/utils';
import SurveyDetail from '../../../../common/components/survey_details/SurveyDetailsComponent';

const Content = ({
  surveyId,
  setSurveys,
  getSurveyDetails,
  surveys,
  surveyDetails,
  isSubmitted,
  submittedSurveyId,
  isFetching,
}) => {
  useEffect(() => {
    if (isEmpty(surveyId)) {
      return;
    }

    setSurveys({ primaryAssigneeSurveys: [surveyId] });
    getSurveyDetails({
      body: {
        surveyId,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitted]);

  const getSurveyDetailsNotFoundBlock = () => {
    const alertMessage = isEmpty(surveyId)
      ? 'Survey id not supplied'
      : `No survey found for the supplied survey id : ${surveyId}`;
    return <Alert type="error">{alertMessage}</Alert>;
  };

  const getSurveyDetailsBlock = () => {
    const primarySurveyIds = get(surveys, 'primaryAssigneeSurveys', []);
    const primarySurveyDetails = getSurveyDetailsList(primarySurveyIds, surveyDetails);

    if (isEmpty(primarySurveyDetails) && !isFetching) return getSurveyDetailsNotFoundBlock();
    return <SurveyDetail assignees={primarySurveyDetails} isPrimaryAssignee />;
  };

  return (
    <Box margin={{ bottom: 'l' }}>
      <Box margin={{ top: 's' }} padding={{ top: 'xxl', horizontal: 's' }}>
        <Grid
          gridDefinition={[
            { colspan: { xl: 8, l: 8, s: 10, xxs: 10 }, offset: { l: 2, xxs: 1 } },
            { colspan: { xl: 2, l: 3, s: 4, xxs: 10 }, offset: { s: 0, xxs: 1 } },
          ]}
        >
          <div className="custom-home-main-content-area">
            <SpaceBetween size="s">
              {isSubmitted && (
                <SurveySubmittedAlert
                  surveyDetails={surveyDetails}
                  submittedSurveyId={submittedSurveyId}
                />
              )}
              {getSurveyDetailsBlock()}
            </SpaceBetween>
          </div>
        </Grid>
      </Box>
    </Box>
  );
};

Content.propTypes = {
  surveyId: PropTypes.string.isRequired,
  surveys: PropTypes.object.isRequired,
  surveyDetails: PropTypes.object.isRequired,
  isSubmitted: PropTypes.bool,
  setSurveys: PropTypes.func.isRequired,
  getSurveyDetails: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  submittedSurveyId: PropTypes.string,
};

const mapStateToProps = state => ({
  surveys: get(state, 'entities.surveys.data', {}) || {},
  surveyDetails: get(state, 'entities.surveyDetails.data', {}) || {},
  isSubmitted: get(state, 'router.location.state.submitted'),
  submittedSurveyId: get(state, 'router.location.state.surveyId'),
  surveyId: get(state, 'currentDashboard.surveyId'),
  isFetching: get(state, 'entities.surveyDetails.isFetching', false),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setSurveys: setSurveysActon,
      getSurveyDetails: getSurveyDetailsAction.BEGIN,
    },
    dispatch,
  );
Content.defaultProps = {
  submittedSurveyId: '',
};

export default connect(mapStateToProps, mapDispatchToProps)(Content);
