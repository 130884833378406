import React, { useState } from 'react';
import { HelpPanel, Icon } from '@amzn/awsui-components-react';
import { TOOLS_CONTENT_FN } from '../../constants/steps-config';
import SurveySteps from '../../constants/SurveySteps';

const getFormattedToolsContent = tools => (
  <HelpPanel
    header={<h2>{tools.title}</h2>}
    footer={
      <>
        <h3>
          Learn more{' '}
          <span role="img" aria-label="Icon external Link">
            <Icon name="external" />
          </span>
        </h3>
      </>
    }
  >
    {tools.content}
  </HelpPanel>
);

export default (templateId, surveyType, derivedClientId) => {
  const steps = SurveySteps(templateId, surveyType, derivedClientId);
  const TOOLS_CONTENT = TOOLS_CONTENT_FN(templateId);
  const getDefaultToolsContent = activeIndex => TOOLS_CONTENT[steps[activeIndex].stateKey].default;

  const [toolsContent, setToolsContent] = useState(
    getFormattedToolsContent(getDefaultToolsContent(0)),
  );
  const [isToolsOpen, setIsToolsOpen] = useState(false);

  const setFormattedToolsContent = tools => {
    setToolsContent(getFormattedToolsContent(tools));
  };

  const openTools = tools => {
    if (tools) {
      setFormattedToolsContent(tools);
    }
    setIsToolsOpen(true);
  };
  const closeTools = () => setIsToolsOpen(false);

  const onToolsChange = evt => setIsToolsOpen(evt.detail.open);

  return {
    toolsContent,
    isToolsOpen,
    openTools,
    closeTools,
    setFormattedToolsContent,
    onToolsChange,
  };
};
