import { useEffect, useState } from 'react';
import { cloneDeep, filter, forEach, get, isEmpty, map, size, isNull, set, max } from 'lodash';
import { sortBy } from 'lodash/collection';
import { useDispatch } from 'react-redux';
import {
  apiResponses,
  initialState,
  TOOLS_CONTENT_FN,
  validationInitialState,
} from '../../constants/steps-config';
import stepsFn from '../../constants/SurveySteps';
import {
  GET_ACTIVITIES_ENDPOINT,
  GET_EMPLOYEES_FOR_SURVEY_ENDPOINT,
  GET_PROJECT_ALLOCATION,
  GET_PROJECTS_ENDPOINT,
  SUBMIT_SURVEY,
  VALARI_API,
  GET_PROJECT_DOCUMENTS,
  GET_VENDORS_FOR_SURVEY_ENDPOINT,
} from '../../../../common/config/api_endpoints';
import addContextToPayload from '../../../../common/utils/api_util';
import { getRoutePathWithStudyPeriod, ROUTE_PATH } from '../../../../common/constants/route_paths';
import SURVEY_LOCK_ACTIONS from '../../../../common/constants/survey_lock_actions';
import metricsUtility from '../../../../common/components/metrics/MetricsUtility';
import {
  METRICS_METHOD,
  METRICS_TIMER,
} from '../../../../common/components/metrics/metrics_constants';
import { isReadOnly } from '../../utils/survey_page_utils';
import { setProjectAllocation } from '../../redux/actions/projectAllocationActions';
import { setActivityDualAllocation } from '../../redux/actions/activityDualAllocationActions';
import { setActivityAllocation } from '../../redux/actions/activityAllocationActions';
import validateEmployee from '../../constants/validations/Employee';
import validateSubmit from '../../constants/validations/Submit';
import validateProjectDocuments from '../../constants/validations/ProjectDocuments';
import validateActivityDualAllocation from '../../constants/validations/ActivityDualAllocation';
import validateActivityAllocation from '../../constants/validations/ActivityAllocation';
import validateActivities from '../../constants/validations/Activity';
import validateProjectAllocation from '../../constants/validations/ProjectAllocation';
import validateProjects from '../../constants/validations/Project';

const parser = data => {
  try {
    return JSON.parse(data);
  } catch (e) {
    return null;
  }
};

export default ({
  closeTools,
  setFormattedToolsContent,
  surveyDetails,
  navigateTo,
  currentDashboard,
  templateId,
  saveSurvey,
  secondaryAssigneesList,
  surveyId,
  studyPeriod,
  updateSurveyLockData,
  updateSurveyLock,
  surveyType,
  activityAllocation,
  activityDualAllocation,
  projectAllocation,
  submitAttestation,
  derivedClientId,
}) => {
  const dispatch = useDispatch();
  const steps = stepsFn(templateId, surveyType, derivedClientId);
  const TOOLS_CONTENT = TOOLS_CONTENT_FN(templateId);
  const getDefaultToolsContent = activeIndex => TOOLS_CONTENT[steps[activeIndex].stateKey].default;
  const [pageElements, setPageElements] =
    surveyDetails && size(surveyDetails.secondaryAssignees) !== 0
      ? useState({ ...apiResponses, SecondaryAssignees: surveyDetails.secondaryAssignees })
      : useState(apiResponses);
  const [surveyInfo, setSurveyInfo] =
    surveyDetails && parser(surveyDetails.userResponse) !== null
      ? useState(parser(surveyDetails.userResponse))
      : useState(initialState);
  const { ProjectAllocation } = surveyInfo;
  const { ActivityDualAllocation } = surveyInfo;
  const { ActivityAllocation } = surveyInfo;
  const { activeStep } = surveyInfo;
  const { validationOnNextButton } = steps[activeStep];
  const [validationState, setValidationState] = useState(validationInitialState);

  // Everytime surveyInfo is updated, we are reordering to follow the order present in
  // defaultSteps. If any key is not present in defaultSteps, they are appended at the end
  // of surveyInfo. While updating the value, we check if new value is different from
  // previous value of surveyInfo, only then we update it to avoid infinite looping.
  useEffect(() => {
    const stepsOrder = steps.map(step => step.stateKey);
    const reorderedStateInfo = Object.fromEntries(
      stepsOrder.filter(key => key in surveyInfo).map(key => [key, surveyInfo[key]]),
    );

    Object.keys(surveyInfo).forEach(key => {
      if (!stepsOrder.includes(key)) {
        reorderedStateInfo[key] = surveyInfo[key];
      }
    });

    if (JSON.stringify(reorderedStateInfo) !== JSON.stringify(surveyInfo)) {
      setSurveyInfo(reorderedStateInfo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [surveyInfo]);

  useEffect(() => {
    if (isEmpty(surveyDetails)) {
      return;
    }

    metricsUtility.invalidateTimerAndPublish(
      METRICS_METHOD.SURVEY_WIZARD,
      METRICS_TIMER.SURVEY_STEP,
    );

    const { stateKey } = steps[activeStep];
    metricsUtility.startTimer(METRICS_TIMER.SURVEY_STEP, `${stateKey}-Timer`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  const setActivityInfo = activityList => {
    setSurveyInfo({
      ...surveyInfo,
      Activity: {
        ...surveyInfo.Activity,
        data: {
          ...get(surveyInfo, 'Activity.data'),
          defaultItems: filter(activityList, ob => ob.isActivityDefault === 'Yes'),
        },
      },
    });
  };

  useEffect(() => {
    if (ProjectAllocation) {
      dispatch(setProjectAllocation(ProjectAllocation));
    }
    if (ActivityDualAllocation) {
      dispatch(setActivityDualAllocation(ActivityDualAllocation));
    }
    if (ActivityAllocation) {
      dispatch(setActivityAllocation(ActivityAllocation));
    }

    if (Object.keys(surveyDetails).length === 0) return;
    Promise.all([
      addContextToPayload(VALARI_API, GET_EMPLOYEES_FOR_SURVEY_ENDPOINT, studyPeriod, {
        body: {
          surveyId: surveyDetails.surveyId,
          clientId: surveyDetails.clientId,
        },
      }),
      addContextToPayload(VALARI_API, GET_ACTIVITIES_ENDPOINT, studyPeriod, {
        body: {
          surveyType: surveyDetails.surveyType,
        },
      }),
      addContextToPayload(VALARI_API, GET_PROJECTS_ENDPOINT, studyPeriod, {
        body: {
          surveyId: surveyDetails.surveyId,
          clientId: surveyDetails.clientId,
        },
      }),
      addContextToPayload(VALARI_API, GET_VENDORS_FOR_SURVEY_ENDPOINT, studyPeriod, {
        body: {
          surveyId: surveyDetails.surveyId,
          clientId: surveyDetails.clientId,
        },
      }),
    ]).then(r => {
      const activityList = map(
        get(r[1], 'body.activityList', []),
        ({ activityName, activityDescription, isActivityDefault, activityId }) => {
          return {
            name: activityName,
            description: activityDescription,
            id: activityId,
            isActivityDefault,
          };
        },
      );
      const employeeList = sortBy(
        map(parser(get(r[0], 'employeeData', '{}')), data => {
          return {
            ...data,
            key: get(data, 'employeeId', ''),
          };
        }),
        employee => get(employee, 'formattedEmployeeName', ''),
      );
      const projectList = parser(get(r[2], 'body.data', '[]'));
      const vendorList = parser(get(r[3], 'vendorData', '{}'));
      setPageElements({
        ...pageElements,
        Employee: {
          employeeList,
        },
        Activity: {
          activityList,
        },
        Project: {
          projectList,
        },
        Vendor: {
          vendorList,
        },
      });

      const selectedProjectIds = new Set();
      if (isNull(surveyDetails.userResponse) || surveyDetails.userResponse === '') {
        addContextToPayload(VALARI_API, GET_PROJECT_ALLOCATION, studyPeriod, {
          body: {
            employeeIdList: map(employeeList, ({ recordId }) => recordId),
            projectIdList: map(projectList, ({ projectId }) => projectId),
            vendorIdList: map(vendorList, ({ recordId }) => recordId),
          },
        }).then(response => {
          const projectMappings = get(response, 'projectAllocationMappings', null);
          if (projectMappings !== null) {
            forEach(Object.keys(projectMappings), entity => {
              forEach(Object.keys(projectMappings[entity]), projectId => {
                selectedProjectIds.add(projectId);
              });
            });

            const selectedProjects = map(
              filter(projectList, ({ projectId }) => selectedProjectIds.has(projectId)),
              ({ projectId, projectDescription, projectName, isUserCreated }) => {
                return {
                  id: projectId,
                  description: projectDescription,
                  name: projectName,
                  isUserCreated,
                };
              },
            );

            setSurveyInfo({
              ...surveyInfo,
              Activity: {
                data: {
                  defaultItems: filter(activityList, ob => ob.isActivityDefault === 'Yes'),
                },
              },
              Project: {
                data: { selectedProjects },
              },
              ProjectAllocation: { data: projectMappings },
            });
          } else setActivityInfo(activityList);
        });
      } else {
        const employeesPresent = new Set(map(employeeList, ({ recordId }) => recordId));
        const vendorsPresent = new Set(map(vendorList, ({ recordId }) => recordId));
        const syncedProjectAllocation = {};
        const syncedActivityAllocation = {};
        const syncedActivityDualAllocation = {}; // For ActivityDualAllocation

        forEach(
          filter(
            Object.keys(projectAllocation),
            ob => employeesPresent.has(ob) || vendorsPresent.has(ob),
          ),
          empId => {
            syncedProjectAllocation[empId] = projectAllocation[empId];
          },
        );
        forEach(
          filter(
            Object.keys(activityAllocation.data),
            ob => employeesPresent.has(ob) || vendorsPresent.has(ob),
          ),
          empId => {
            syncedActivityAllocation[empId] = activityAllocation.data[empId];
          },
        );

        forEach(
          filter(
            Object.keys(activityDualAllocation.data),
            ob => employeesPresent.has(ob) || vendorsPresent.has(ob),
          ),
          empId => {
            syncedActivityDualAllocation[empId] = activityDualAllocation.data[empId];
          },
        );

        const selectedProjects = get(surveyInfo, 'Project.data.selectedProjects', []);

        const request = {
          body: {
            projectIdList: selectedProjects.map(project => project.id),
            surveyId,
          },
        };
        addContextToPayload(VALARI_API, GET_PROJECT_DOCUMENTS, studyPeriod, request).then(
          response => {
            const projectDocuments = JSON.parse(get(response, 'body.projectIdToDocumentDataMap'));
            setSurveyInfo({
              ...surveyInfo,
              Activity: {
                ...surveyInfo.Activity,
                data: {
                  ...get(surveyInfo, 'Activity.data'),
                  defaultItems: filter(activityList, ob => ob.isActivityDefault === 'Yes'),
                },
              },
              ProjectAllocation: {
                ...surveyInfo.ProjectAllocation,
                data: syncedProjectAllocation,
              },
              ActivityAllocation: {
                ...surveyInfo.ActivityAllocation,
                data: syncedActivityAllocation,
              },
              ActivityDualAllocation: {
                ...surveyInfo.ActivityDualAllocation,
                data: syncedActivityDualAllocation,
              },
              ProjectDocuments: {
                data: projectDocuments,
                error: null,
              },
            });
          },
        );
        selectedProjects.forEach(selectedProject => {
          projectList.forEach(project => {
            if (project.projectId === selectedProject.id) {
              set(selectedProject, 'name', project.projectName);
              set(selectedProject, 'description', project.projectDescription);
            }
          });
        });
        setSurveyInfo({
          ...surveyInfo,
          Project: {
            data: { selectedProjects },
          },
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeStateHandler = detail => {
    setSurveyInfo({ ...surveyInfo, [detail.key]: detail.value });
  };

  const setActiveStepIndexAndCloseTools = index => {
    setSurveyInfo({ ...surveyInfo, activeStep: index });
    setFormattedToolsContent(getDefaultToolsContent(index));
    closeTools();
  };

  // const validateVendor = () => {
  //   // TODO = need to add validation here
  //   return true;
  // };

  const saveSurveyCall = (payload, surveyInfoUserResponse) => {
    const activeStepContext = get(
      JSON.parse(get(surveyDetails, 'userResponse', '{}')),
      'activeStep',
      0,
    );

    saveSurvey({
      ...payload,
      body: {
        clientId: surveyDetails.clientId,
        surveyId: surveyDetails.surveyId,
        userResponse: JSON.stringify({
          ...surveyInfo,
          activeStep: max([surveyInfo.activeStep, activeStepContext]),
          ...surveyInfoUserResponse,
        }),
        secondaryAssignees: secondaryAssigneesList,
        version: surveyDetails.lastUpdatedOn,
        sessionId: updateSurveyLockData.sessionId,
      },
    });
  };

  const submitCall = surveyInfoUserResponse => {
    const request = {
      body: {
        surveyId: surveyDetails.surveyId,
        clientId: surveyDetails.clientId,
        userResponse: JSON.stringify({
          ...surveyInfo,
          ...surveyInfoUserResponse,
        }),
        secondaryAssignees: secondaryAssigneesList,
        version: surveyDetails.lastUpdatedOn,
        sessionId: updateSurveyLockData.sessionId,
      },
    };
    addContextToPayload(VALARI_API, SUBMIT_SURVEY, studyPeriod, request).then(response => {
      const dashboardPath = get(currentDashboard, 'routePath', ROUTE_PATH.DASHBOARD);
      const navigationState = { pathname: getRoutePathWithStudyPeriod(dashboardPath, studyPeriod) };
      if (response.status === 200) {
        if (dashboardPath === ROUTE_PATH.RND_DASHBOARD)
          navigateTo({
            ...navigationState,
            search: `?surveyId=${surveyId}`,
            state: { submitted: true, surveyId },
          });
        else navigateTo({ ...navigationState, state: { submitted: true, surveyId } });
      } else {
        setSurveyInfo({
          ...surveyInfo,
          SubmitAPI: {
            ...surveyInfo.SubmitAPI,
            error: get(response, 'statusMessage', null),
          },
          ...surveyInfoUserResponse,
        });
      }
    });
  };

  const validationSteps = {
    Employee: validateEmployee,
    Project: () => validateProjects(surveyInfo),
    ProjectAllocation: () => validateProjectAllocation(pageElements, surveyType, projectAllocation),
    Activity: validateActivities,
    ActivityAllocation: () =>
      validateActivityAllocation(pageElements, surveyType, activityAllocation),
    ActivityDualAllocation: () =>
      validateActivityDualAllocation(pageElements, surveyType, activityDualAllocation),
    ProjectDocuments: validateProjectDocuments,
    Submit: () => validateSubmit(surveyId, submitAttestation, surveyType),
    // TODO: Add vendors in UserResponse
    // Vendor: validateVendor,
  };

  const checkErrorOnSubmit = nextStep => {
    const prevStep = surveyInfo.activeStep;
    const pages = Object.keys(surveyInfo);
    const errorPageKey = pages[prevStep];
    setSurveyInfo({
      ...surveyInfo,
      activeStep: nextStep,
      [errorPageKey]: {
        ...surveyInfo[errorPageKey],
        error: !(validationSteps[errorPageKey] && validationSteps[errorPageKey]()),
        errorMessage: null,
      },
      SubmitAPI: {
        ...get(surveyInfo, 'SubmitAPI', {}),
        error: null,
      },
    });
  };

  const onNavigate = (event, surveyInfoUserResponse) => {
    let navigationAllowed = true;
    const { detail } = event;
    const nextStep = detail.requestedStepIndex;
    if (nextStep - activeStep > 0) {
      if (size(validationOnNextButton) !== 0) {
        validationOnNextButton.forEach(validate => {
          navigationAllowed =
            navigationAllowed &&
            validate({
              surveyInfo,
              pageElements,
              setValidationState,
              surveyType,
            });
        });
      }
    }
    if (navigationAllowed) {
      if (isReadOnly(surveyDetails)) {
        checkErrorOnSubmit(nextStep);
      } else {
        saveSurveyCall(
          {
            triggeredByAutosave: false,
            onSuccess: () => {
              checkErrorOnSubmit(nextStep);
            },
          },
          surveyInfoUserResponse,
        );
      }
    }
  };

  const backToHome = () => {
    const dashboardPath = get(currentDashboard, 'routePath', ROUTE_PATH.DASHBOARD);
    const navigationState = { pathname: getRoutePathWithStudyPeriod(dashboardPath, studyPeriod) };
    if (dashboardPath === ROUTE_PATH.RND_DASHBOARD)
      navigateTo({
        ...navigationState,
        search: `?surveyId=${surveyId}`,
        state: { submitted: false, surveyId },
      });
    else navigateTo({ ...navigationState, state: { submitted: false } });
  };

  const onCancel = surveyInfoUserResponse => {
    if (isReadOnly(surveyDetails)) {
      backToHome();
    } else {
      saveSurveyCall(
        {
          triggeredByAutosave: false,
          onSuccess: () => {
            updateSurveyLock({
              onSuccess: () => {
                backToHome();
              },
              body: {
                clientId: surveyDetails.clientId,
                surveyId,
                actionType: SURVEY_LOCK_ACTIONS.Release,
                sessionId: updateSurveyLockData.sessionId,
              },
            });
          },
        },
        surveyInfoUserResponse,
      );
    }
  };

  const checkValidation = () => {
    const updated = cloneDeep(surveyInfo);
    let isError = false;

    steps.forEach(step => {
      const errorPageKey = step.stateKey;
      if (!validationSteps[errorPageKey]()) {
        isError = true;
        updated[errorPageKey] = {
          ...updated[errorPageKey],
          error: true,
        };
      } else {
        updated[errorPageKey] = {
          ...updated[errorPageKey],
          error: false,
        };
      }
    });

    return isError ? updated : null;
  };

  const onSubmit = surveyInfoUserResponse => {
    const obj = checkValidation();
    if (obj) {
      setSurveyInfo({ ...obj });
    } else if (isReadOnly(surveyDetails)) {
      backToHome();
    } else {
      submitCall(surveyInfoUserResponse);
    }
  };

  return {
    surveyInfo,
    pageElements,
    setActiveStepIndexAndCloseTools,
    changeStateHandler,
    onNavigate,
    onCancel,
    onSubmit,
    setPageElements,
    validationState,
    setValidationState,
  };
};
