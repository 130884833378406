import React, { useEffect, useState } from 'react';
import { Alert, Box, Button, Modal, SpaceBetween } from '@amzn/awsui-components-react';
import { filter, forEach, get, isEmpty, map } from 'lodash';
import PropTypes from 'prop-types';

import { round, sum } from 'lodash/math';
import { values } from 'lodash/object';
import { keys } from 'lodash/fp/_util';
import * as XLSX from 'xlsx';
import { connect, useDispatch } from 'react-redux';
import AllocationGrid from '../../AllocationGrid/AllocationGrid';
import { errorMessages, validationInitialState } from '../../../constants/steps-config';
import { getEntityData, getSurveyEntity } from '../../../../../common/constants/surveyType';
import { isReadOnly } from '../../../utils/survey_page_utils';
import FillMatrixWithExcel from '../../AllocationGrid/FillMatrixWithExcel';
import { setProjectAllocation as setProjectAllocationAction } from '../../../redux/actions/projectAllocationActions';
import { setCopyGridValues as setCopyGridValuesAction } from '../../../redux/actions/copyGridValuesActions';

const ProjectAllocation = ({
  state,
  setState,
  pageContents,
  validationState,
  setValidationState,
  onNavigate,
  surveyType,
  surveyDetails,
  projectAllocation,
  copyGridValues,
}) => {
  const dispatch = useDispatch();
  const entityData = getEntityData(pageContents, surveyType);
  const projectRemoved = get(validationState, 'projectAllocationState.projectRemoved', false);
  const selectedProject = get(state, 'Project.data.selectedProjects', {});

  const setCopyGridValues = (value, column) => {
    dispatch(
      setCopyGridValuesAction({
        ...copyGridValues,
        projectCopiedValues: value,
        projectCopiedColumn: column,
      }),
    );
  };

  const setProjectAllocation = val => {
    dispatch(setProjectAllocationAction(val));
  };

  const validateProjectAllocation = project => {
    const entityList = entityData;
    if (isEmpty(project) || Object.keys(project).length !== entityList.length) return false;
    let validation = true;
    Object.keys(project).forEach(employee => {
      if (Number(project[employee].total) !== 100) {
        validation = false;
      }
    });
    return validation;
  };

  useEffect(() => {
    const Projects = map(get(state, 'Project.data.selectedProjects', []), ob => ob.id);
    const oldState = projectAllocation?.data;

    const newState = {};
    forEach(keys(oldState), ob => {
      const x = {};
      forEach(Projects, key => {
        if (oldState[ob][key] && oldState[ob][key] !== '0') x[key] = oldState[ob][key];
      });
      x.total = sum(map(values(x), val => round(parseFloat(val), 1)))
        .toFixed(1)
        .toString();
      newState[ob] = x;
    });
    setProjectAllocation({
      ...projectAllocation,
      data: newState,
      error: !validateProjectAllocation(newState),
      errorMessage: Projects.length === 0 ? get(errorMessages, [surveyType, 'Project']) : null,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const projects = get(state, 'Project.data.selectedProjects', []);

  const uploadedData = importedData => {
    if (importedData.status === 'error') {
      setProjectAllocation({
        ...projectAllocation,
        errorMessage: importedData.errorMessage,
      });
    } else {
      setProjectAllocation({
        ...projectAllocation,
        data: importedData,
        error: !validateProjectAllocation(importedData),
        errorMessage: null,
      });
    }
  };

  const updateActiveIndex = () => {
    setState({
      key: 'activeStep',
      value: 3,
    });
  };
  const closeNoAllocationModal = () => {
    setValidationState(validationInitialState);
  };
  useEffect(() => {
    if (projectRemoved) {
      setValidationState(validationInitialState);
      const detail = {
        requestedStepIndex: state.activeStep + 1,
      };
      onNavigate({ detail });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProject, projectRemoved]);
  const removeProjectFromSelectedList = () => {
    const currentSelectedProject = get(state, 'Project.data.selectedProjects', {});
    const removeProjectId = validationState.projectAllocationState.projectId;
    const currentSelection = filter(currentSelectedProject, project => {
      return project.id !== removeProjectId;
    });
    setState({
      key: 'Project',
      value: {
        ...state.Project,
        data: {
          ...get(state, 'Project.data', {}),
          selectedProjects: currentSelection,
        },
      },
    });
    setValidationState(prevState => {
      return {
        ...prevState,
        projectAllocationState: {
          ...prevState.projectAllocationState,
          projectRemoved: true,
        },
      };
    });
  };

  const { error, errorMessage } = projectAllocation;

  const exportGridToExcel = (
    colsEntity,
    rowsEntity,
    gridValues,
    fileName = 'project-allocation-grid.xlsx',
  ) => {
    const worksheetData = [];

    const headerRow = ['Projects', ...colsEntity.map(col => col.name)];
    worksheetData.push(headerRow);

    rowsEntity.forEach(row => {
      const rowData = [row.name];
      colsEntity.forEach(col => {
        const cellValue = gridValues[col.id] ? gridValues[col.id][row.id] || '' : '';
        rowData.push(cellValue);
      });
      worksheetData.push(rowData);
    });

    // Convert the worksheet data to a worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'projects');

    XLSX.writeFile(workbook, fileName);
  };

  const handleExportClick = (colsEntity, rowsEntity, gridValues) => {
    exportGridToExcel(colsEntity, rowsEntity, gridValues);
  };

  const getEmployeeIdByName = (employeeName, colsEntity) => {
    const employee = colsEntity.find(col => col.name === employeeName);
    return employee ? employee.id : null;
  };

  const getProjectIdByName = (projectName, rowsEntity) => {
    const project = rowsEntity.find(row => row.name === projectName);
    return project ? project.id : null;
  };

  const parseExcel = (file, colsEntity, rowsEntity) => {
    const reader = new FileReader();

    reader.onload = function parseExcelOnLoad(e) {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      const [headerRow, ...dataRows] = jsonData;

      const gridData = {};

      dataRows.forEach(row => {
        const projectName = row[0];
        const projectId = getProjectIdByName(projectName, rowsEntity);

        if (projectId) {
          headerRow.slice(1).forEach((employeeName, index) => {
            const employeeId = getEmployeeIdByName(employeeName, colsEntity);
            if (employeeId) {
              const cellValue = row[index + 1] !== undefined ? String(row[index + 1]) : '0';

              if (cellValue !== '' && parseFloat(cellValue) !== 0) {
                if (!gridData[employeeId]) {
                  gridData[employeeId] = {};
                }

                gridData[employeeId][projectId] = cellValue;
              }
            }
          });
        }
      });

      Object.keys(gridData).forEach(employeeId => {
        gridData[employeeId].total = Object.values(gridData[employeeId])
          .reduce((totalSum, val) => totalSum + parseFloat(String(val) || '0'), 0)
          .toFixed(1);
      });

      const newGridData = {
        ...projectAllocation?.data,
        ...gridData,
      };

      setProjectAllocation({
        ...projectAllocation,
        data: newGridData,
        error: !validateProjectAllocation(newGridData),
        errorMessage: null,
      });
    };

    reader.readAsBinaryString(file);
  };

  const handleFileSelected = file => {
    if (file) {
      parseExcel(file, entityData, projects);
    }
  };

  const [excelUploadModal, setExcelUploadModal] = useState(false);

  return (
    <SpaceBetween size="xs">
      {error && (
        <Alert visible="true" dismissAriaLabel="Close alert" type="error">
          You must allocate a total of 100% to each {getSurveyEntity(surveyType)}
        </Alert>
      )}

      {errorMessage && (
        <Alert visible="true" dismissAriaLabel="Close alert" type="error">
          {errorMessage}
        </Alert>
      )}

      <Box float="right">
        <Button onClick={() => setExcelUploadModal(true)} disabled={isReadOnly(surveyDetails)}>
          Fill matrix with Excel
        </Button>
      </Box>

      <FillMatrixWithExcel
        isVisible={excelUploadModal}
        setModalVisible={setExcelUploadModal}
        onDownloadTemplate={() => handleExportClick(entityData, projects, projectAllocation?.data)}
        onFillMatrix={handleFileSelected}
      />

      <AllocationGrid
        titleEnitity="projects"
        title="Project Matrix"
        colsEntity={entityData}
        rowsEntity={projects}
        uploadedData={uploadedData}
        selectEntity={updateActiveIndex}
        isDownloadUploadButtonDisabled={projects.length === 0}
        allocationType="Project"
        surveyType={surveyType}
        surveyDetails={surveyDetails}
        gridValues={projectAllocation?.data}
        setGridValues={val => {
          const newGridData = {
            ...projectAllocation?.data,
            ...val,
          };
          const newAllocationData = {
            ...projectAllocation,
            data: newGridData,
            error: !validateProjectAllocation(newGridData),
            errorMessage: null,
          };
          setProjectAllocation(newAllocationData);
        }}
        copiedValues={copyGridValues.projectCopiedValues}
        copiedColumn={copyGridValues.projectCopiedColumn}
        setCopiedValues={setCopyGridValues}
      />
      <Modal
        onDismiss={closeNoAllocationModal}
        visible={validationState.projectAllocationState.modalVisible}
        closeAriaLabel="Close modal"
        size="medium"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="normal" onClick={closeNoAllocationModal}>
                Edit allocation
              </Button>
              <Button variant="primary" onClick={removeProjectFromSelectedList}>
                Proceed
              </Button>
            </SpaceBetween>
          </Box>
        }
        header="Project has no time allocation"
      >
        <div>
          You didn&apos;t allocate any of the employee&apos;s time to project{' '}
          <b>{validationState.projectAllocationState.projectName}</b>. If you choose to proceed,
          this project will be removed from your project list.
        </div>
      </Modal>
    </SpaceBetween>
  );
};
ProjectAllocation.propTypes = {
  state: PropTypes.object.isRequired,
  setState: PropTypes.func.isRequired,
  pageContents: PropTypes.object.isRequired,
  surveyType: PropTypes.string.isRequired,
  validationState: PropTypes.object.isRequired,
  setValidationState: PropTypes.func.isRequired,
  onNavigate: PropTypes.func.isRequired,
  surveyDetails: PropTypes.object.isRequired,
  projectAllocation: PropTypes.object.isRequired,
  copyGridValues: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  projectAllocation: get(state, 'projectAllocation', {}),
  copyGridValues: get(state, 'copyGridValues', {}),
});

export default connect(mapStateToProps)(ProjectAllocation);
