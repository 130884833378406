import { has } from 'lodash/object';
import { GET_SURVEY_DETAILS_ENDPOINT } from '../../../../common/config/api_endpoints';
import {
  createAPILifecycleActions,
  createAPILifecycleActionTypes,
} from '../../../../redux/actions/action_utils';
import createAmplifyAPICallEpic from '../../../../redux/epics/utils';
import { updateSecondaryAssigneesAction } from '../../../survey_page/redux/secondaryAssigneesMap';

const GET_SURVEY_DETAILS_ACTION_TYPES = createAPILifecycleActionTypes(
  'GET_SURVEY_DETAILS_ACTION_TYPES',
);
export const getSurveyDetailsAction = createAPILifecycleActions(
  GET_SURVEY_DETAILS_ACTION_TYPES.NAME,
);

const getSurveyDetails = payload => {
  if (has(payload, ['body', 'surveyId'])) {
    return {
      [payload.body.surveyId]: payload.body,
    };
  }

  return {};
};

const initialState = {
  isFetching: false,
  data: null,
  error: null,
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_SURVEY_DETAILS_ACTION_TYPES.BEGIN:
      return {
        ...state,
        isFetching: true,
      };
    case GET_SURVEY_DETAILS_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: {
          ...state.data,
          ...getSurveyDetails(payload),
        },
        error: null,
      };
    case GET_SURVEY_DETAILS_ACTION_TYPES.FAILURE:
      return {
        ...state,
        isFetching: false,
        data: null,
        error: payload,
      };

    case GET_SURVEY_DETAILS_ACTION_TYPES.CLEAR:
      return initialState;
    default:
      return state;
  }
};

const successCallback = response => {
  const actionsOut = [getSurveyDetailsAction.SUCCESS(response)];
  if (response && response.body) {
    const { surveyId, secondaryAssignees } = response.body;
    actionsOut.push(updateSecondaryAssigneesAction({ [surveyId]: secondaryAssignees }));
  }
  return actionsOut;
};

export const getSurveyDetailsEpic = createAmplifyAPICallEpic({
  actionType: GET_SURVEY_DETAILS_ACTION_TYPES.BEGIN,
  api: GET_SURVEY_DETAILS_ENDPOINT,
  successCallback,
  errorCallback: () => [getSurveyDetailsAction.FAILURE()],
});
