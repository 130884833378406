import { Box, Grid } from '@amzn/awsui-components-react';
import React from 'react';
import '../css/RnDLandingPage.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PageHeader from '../../../../common/components/page_header/PageHeader';
import { getStudyPeriod } from '../../../../common/constants/study_period';

const Header = ({ studyPeriod }) => (
  <div className="custom-rnd-home__header page">
    <PageHeader />
    <Box padding={{ vertical: 'xxl', horizontal: 's' }}>
      <Grid
        gridDefinition={[
          { offset: { l: 2, xxs: 1 }, colspan: { l: 8, xxs: 10 } },
          { colspan: { xl: 10 }, offset: { l: 2, xxs: 1 } },
        ]}
      >
        <Box padding={{ top: 'xxl' }} />
        <div className="custom-home__header-title">
          <Box
            variant="h1"
            fontWeight="bold"
            padding={{ top: 'l' }}
            fontSize="display-l"
            color="inherit"
          >
            R&D tax credit study {studyPeriod}
          </Box>
          <Box variant="p">
            <span className="custom-home__header-sub-title">
              Welcome to R&D survey dashboard. You can choose to edit the survey below. You will be
              guided through multiple steps to provide information on the types of activities and
              projects which the concerned team has performed in {studyPeriod}.
            </span>
          </Box>
        </div>
      </Grid>
    </Box>
  </div>
);

Header.propTypes = {
  studyPeriod: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  studyPeriod: getStudyPeriod(state),
});

export default connect(mapStateToProps)(Header);
