export const ROUTE_PATH = {
  HOME_SCREEN: '/',
  LOGIN: '/login',
  DASHBOARD: '/dashboard',
  RND_DASHBOARD: '/rnd-dashboard',
  NOT_FOUND_SCREEN: '/not-found',
  SURVEY: '/survey',
};

export function getRoutePathWithStudyPeriod(routePath, studyPeriod) {
  return `/${studyPeriod}${routePath}`;
}

export function getRoutePathWithStudyPeriodPlaceholder(routePath) {
  return getRoutePathWithStudyPeriod(routePath, ':studyPeriod');
}
