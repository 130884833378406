import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ExpandableSection, Icon } from '@amzn/awsui-components-react';
import './ActivityAllocationGrid.css';
import { get } from 'lodash';
import { connect } from 'react-redux';
import AllocationGrid from '../../../AllocationGrid/AllocationGrid';

const ActivityAllocationGrid = ({
  entityData,
  projectList,
  activities,
  surveyType,
  surveyDetails,
  activityDualAllocation,
  setActivityDualAllocation,
  copiedValues,
  copiedColumn,
  setCopiedValues,
}) => {
  const [statusMap, setStatusMap] = useState({});

  const checkStatus = () => {
    const newStatusMap = {};

    entityData.forEach(entity => {
      const colsEntity = projectList[entity.id]
        ? projectList[entity.id].map(project => ({
            id: project.id,
            name: project.name,
          }))
        : [];

      if (colsEntity.length === 0) {
        newStatusMap[entity.id] = 'PENDING';
        return;
      }

      let allColumnsValid = true;
      let hasData = false;

      colsEntity.forEach(colEntity => {
        const total = activityDualAllocation?.data[entity.id]?.[colEntity.id]?.total
          ? parseFloat(activityDualAllocation?.data[entity.id][colEntity.id].total)
          : null;

        if (total === null || total === 0 || total !== 100) {
          allColumnsValid = false;
        } else {
          hasData = true;
        }
      });

      if (!hasData) {
        allColumnsValid = false;
      }

      newStatusMap[entity.id] = allColumnsValid ? 'SUCCESS' : 'ERROR';
    });

    setStatusMap(newStatusMap);
  };

  useEffect(() => {
    checkStatus();
  }, [activityDualAllocation]);

  const getStatusIcon = status => {
    switch (status) {
      case 'SUCCESS':
        return <Icon name="status-positive" variant="success" size="big" />;
      case 'ERROR':
        return <Icon name="status-warning" variant="error" size="big" />;
      case 'PENDING':
      default:
        return <Icon name="status-positive" variant="disabled" size="big" />;
    }
  };

  return (
    <div className="activity-allocation-grid">
      {entityData.map(entity => (
        <ExpandableSection
          key={entity.id}
          headerText={`${entity.name.split(',')[0]}, ${entity.name.split(',')[1]}`}
          headerActions={getStatusIcon(statusMap[entity.id])}
          headerDescription={entity.jobTitle}
          variant="container"
        >
          <AllocationGrid
            rowsEntity={activities}
            colsEntity={
              projectList[entity.id]
                ? projectList[entity.id].map(project => ({
                    id: project.id,
                    name: project.name,
                  }))
                : []
            }
            gridValues={activityDualAllocation?.data[entity.id] || {}}
            setGridValues={val => {
              const newAllocationData = {
                ...activityDualAllocation,
                data: {
                  ...activityDualAllocation?.data,
                  [entity.id]: val,
                },
              };
              setActivityDualAllocation(newAllocationData);
            }}
            allocationType="Activity"
            surveyType={surveyType}
            surveyDetails={surveyDetails}
            entityForActivityAllocation={entity}
            copiedValues={copiedValues}
            copiedColumn={copiedColumn}
            setCopiedValues={setCopiedValues}
          />
        </ExpandableSection>
      ))}
    </div>
  );
};

ActivityAllocationGrid.propTypes = {
  entityData: PropTypes.array.isRequired,
  projectList: PropTypes.object.isRequired,
  activities: PropTypes.array.isRequired,
  surveyType: PropTypes.string.isRequired,
  surveyDetails: PropTypes.object.isRequired,
  activityDualAllocation: PropTypes.object.isRequired,
  setActivityDualAllocation: PropTypes.func.isRequired,
  copiedValues: PropTypes.object.isRequired,
  copiedColumn: PropTypes.string.isRequired,
  setCopiedValues: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  activityDualAllocation: get(state, 'activityDualAllocation', {}),
});

export default connect(mapStateToProps)(ActivityAllocationGrid);
