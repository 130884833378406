export const RESOURCE_TYPE = {
  PAGE: 'Page',
  FEATURE: 'Feature',
  DATA: 'Data',
};

export const RESOURCE = {
  PAGE_SME_SURVEY_DASHBOARD: 'SMESurveyDashboard',
  PAGE_RND_SURVEY_DASHBOARD: 'RnDSurveyDashboard',
  PAGE_SME_SURVEY: 'SMESurvey',
};

export const OPERATION = {
  FEATURE_CAN_OPERATE: 'can operate',
  PAGE_CAN_ACCESS: 'can access',
  DATA_CAN_READ: 'can read',
  DATA_CAN_WRITE: 'can write',
};
