export const VALARI_API = 'ValariAPI';

export const LIST_PERMISSIONS_ENDPOINT = '/listPermissions';
export const SEARCH_ASSIGNED_EMPLOYEES_ENDPOINT = '/getAssignedEmployees';
export const GET_EMPLOYEES_FOR_SURVEY_ENDPOINT = '/GetEmployeesForSurvey';
export const GET_SURVEYS_ENDPOINT = '/getAllSurveysForUser';
export const GET_SURVEY_DETAILS_ENDPOINT = '/getSurveyDetails';
export const GET_ACTIVITIES_ENDPOINT = '/getAllActivities';
export const GET_PROJECTS_ENDPOINT = '/getProjectsByCostCenter';
export const SAVE_SURVEY_ENDPOINT = '/saveSurvey';
export const SUBMIT_SURVEY = '/submitSurvey';
export const CREATE_PROJECT = '/modifyProjectBySME';
export const SEARCH_POPULATION_ENDPOINT = '/searchPopulation';
export const REASSIGN_SURVEY_ENDPOINT = '/reassignSurvey';
export const GET_PROJECT_ALLOCATION = '/getProjectAllocation';
export const GET_PROJECT_DOCUMENTS = '/getProjectDocuments';
export const GET_PRESIGNED_URL = '/getDocumentUploadPresignedURL';
export const CREATE_PROJECT_DOCUMENT = '/createProjectDocument';
export const EDIT_PROJECT_DOCUMENT = '/editProjectDocument';
export const DELETE_PROJECT_DOCUMENT = '/deleteProjectDocument';
export const SEARCH_COST_CENTERS = '/searchCostCenters';
export const GET_VENDORS_FOR_SURVEY_ENDPOINT = '/GetVendorsForSurvey';
export const UPDATE_SURVEY_LOCK = '/updateSurveyLock';
export const SEARCH_PROJECT_ENDPOINT = '/searchProjects';
