export const modalTypes = { REMOVE: 'Remove', UNSELECT: 'Unselect' };

export const projectDataInitialState = {
  addProjectModalState: false,
  projectTitle: '',
  projectDesc: '',
  projectId: null,
  projectDetailsModalState: false,
  projectDeleteModalState: false,
  isUserCreated: '',
};

export const deleteProjectModalInitialState = {
  visible: false,
  selectedProject: [],
  newProjects: [],
  type: modalTypes.REMOVE,
};
