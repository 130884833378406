import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Header from './components/Header';
import './css/LandingPage.scss';
import Content from './components/Content';
import Page from '../../../common/components/with_page/Page';
import { RESOURCE } from '../../auth/constants/rbac_constants';
import { setCurrentDashboardAction } from '../common/redux/CurrentDashboard';
import { ROUTE_PATH } from '../../../common/constants/route_paths';
import metricsUtility from '../../../common/components/metrics/MetricsUtility';
import {
  METRICS_METHOD,
  METRICS_TIMER,
} from '../../../common/components/metrics/metrics_constants';

const LandingPage = ({ setCurrentDashboard }) => {
  useEffect(() => {
    metricsUtility.invalidateTimerAndPublish(
      METRICS_METHOD.SURVEY_WIZARD,
      METRICS_TIMER.SURVEY_STEP,
    );
    setCurrentDashboard({
      routePath: ROUTE_PATH.DASHBOARD,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Header />
      <Page
        isPageAccessControlled
        PageId={RESOURCE.PAGE_SME_SURVEY_DASHBOARD}
        disableContentPaddings
        PageContent={<Content />}
      />
    </div>
  );
};

LandingPage.propTypes = {
  setCurrentDashboard: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setCurrentDashboard: setCurrentDashboardAction,
    },
    dispatch,
  );

export default connect(null, mapDispatchToProps)(LandingPage);
