import { SURVEY_TYPE } from '../../../../../common/constants/surveyType';

export const REASONS = {
  [SURVEY_TYPE.LABOR]: [
    { label: 'Not familiar with the employee(s)', value: 'Reason1' },
    { label: 'Not have enough knowledge on assigned employee(s)', value: 'Reason2' },
    { label: 'Other', value: 'Other' },
  ],
  [SURVEY_TYPE.CONTRACT]: [
    { label: 'Not familiar with the vendor(s)', value: 'Reason1' },
    { label: 'Not have enough knowledge on assigned vendor(s)', value: 'Reason2' },
    { label: 'Other', value: 'Other' },
  ],
};

export const reassignInitialState = {
  selectedSME: '',
  filterText: '',
  option: '',
  isOtherReasonVisible: false,
  otherReason: '',
  checked: false,
};
