import { map } from 'lodash';
import { ColumnLayout } from '@amzn/awsui-components-react';
import React from 'react';
import PropTypes from 'prop-types';
import ClickablePhoneToolIcon from '../ClickablePhoneToolIcon/ClickablePhoneToolIcon';

const SecondaryAssigneesCards = ({ list, onCancel, isReadOnly }) => {
  let indexOfEachCard = -1;
  const renderedList = map(list, sme => {
    indexOfEachCard += 1;
    return {
      formattedEmployeeName: sme.label,
      employeeAlias: sme.description,
      jobTitle: sme.tags[0],
      index: indexOfEachCard,
      phoneToolURL: sme.phoneToolURL,
      badgePhotoURL: sme.badgePhotoURL,
    };
  });
  return (
    <ColumnLayout columns={2}>
      {map(
        renderedList,
        ({
          formattedEmployeeName,
          employeeAlias,
          jobTitle,
          index,
          phoneToolURL,
          badgePhotoURL,
        }) => {
          return (
            <ClickablePhoneToolIcon
              formattedEmployeeName={formattedEmployeeName}
              employeeAlias={employeeAlias}
              jobTitle={jobTitle}
              index={index}
              onCancel={onCancel}
              phoneToolURL={phoneToolURL}
              badgePhotoURL={badgePhotoURL}
              isReadOnly={isReadOnly}
            />
          );
        },
      )}
    </ColumnLayout>
  );
};

SecondaryAssigneesCards.propTypes = {
  list: PropTypes.array.isRequired,
  onCancel: PropTypes.func.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
};

export default SecondaryAssigneesCards;
