import React, { useEffect, useState } from 'react';
import { get, join, map, size, sortBy } from 'lodash';
import { Box, SpaceBetween } from '@amzn/awsui-components-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DetailTiles from './DetailTiles';
import { SEARCH_POPULATION_ENDPOINT, VALARI_API } from '../../../config/api_endpoints';
import { ITEM_TYPE_LABOR } from '../../../config/api_constants';
import { getSerializedData } from '../../../../app/survey_page/utils/redux_payload_utils';
import addContextToPayload from '../../../utils/api_util';
import { getStudyPeriod } from '../../../constants/study_period';

const transformReceivedEmployees = ({
  employeeAlias,
  formattedEmployeeName,
  jobTitle,
  phoneToolURL,
  badgePhotoURL,
}) => {
  return { employeeAlias, formattedEmployeeName, jobTitle, phoneToolURL, badgePhotoURL };
};

const getDetailedEmployees = (
  employees,
  detailedEmployees,
  setDetailedEmployees,
  studyPeriod,
  clientId,
) => {
  const query = join(
    map(employees, employee => {
      return `recordId equals ${employee}`;
    }),
    ' || ',
  );

  if (size(employees) > 0) {
    addContextToPayload(VALARI_API, SEARCH_POPULATION_ENDPOINT, studyPeriod, {
      body: {
        clientId,
        itemType: ITEM_TYPE_LABOR,
        pageSize: 50,
        pageNumber: 1,
        query,
      },
    }).then(r => {
      setDetailedEmployees(
        sortBy(
          map(getSerializedData(get(r, 'body.data', [])), res => transformReceivedEmployees(res)),
          ['formattedEmployeeName'],
        ),
      );
    });
  }
};

const DisplayEmployeeInformation = ({ employees, studyPeriod, clientId }) => {
  const [detailedEmployees, setDetailedEmployees] = useState([]);
  useEffect(
    () =>
      getDetailedEmployees(
        employees,
        detailedEmployees,
        setDetailedEmployees,
        studyPeriod,
        clientId,
      ),
    // eslint-disable-next-line
    [employees],
  );
  if (size(employees) === 0) return <Box variant="p">None</Box>;
  return (
    <SpaceBetween size="m" direction="horizontal">
      {map(
        detailedEmployees,
        ({ employeeAlias, formattedEmployeeName, jobTitle, phoneToolURL, badgePhotoURL }) => {
          return (
            <DetailTiles
              formattedEmployeeName={formattedEmployeeName}
              jobTitle={jobTitle}
              employeeAlias={employeeAlias}
              phoneToolURL={phoneToolURL}
              badgePhotoURL={badgePhotoURL}
            />
          );
        },
      )}
    </SpaceBetween>
  );
};

DisplayEmployeeInformation.propTypes = {
  employees: PropTypes.array.isRequired,
  studyPeriod: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  studyPeriod: getStudyPeriod(state),
});

export default connect(mapStateToProps)(DisplayEmployeeInformation);
