import { Auth } from 'aws-amplify';
import SURVEY_LOCK_ACTIONS from '../../../constants/survey_lock_actions';

const isSurveyLockAcquired = surveyLockDetails => {
  return Auth.currentAuthenticatedUser().then(user => {
    const data = JSON.parse(user?.attributes?.identities ?? '[]');
    const userAlias = Array.isArray(data) && data.length > 0 ? data[0]?.userId : '';
    if (
      surveyLockDetails.lockStatus === SURVEY_LOCK_ACTIONS.Acquire &&
      parseInt(surveyLockDetails.lockAcquiredUntil, 10) - new Date().getTime() > 0 &&
      userAlias !== surveyLockDetails.lockAcquiredByAlias
    ) {
      return true;
    }
    return false;
  });
};
export default isSurveyLockAcquired;
