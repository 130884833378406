import { get } from 'lodash';
import { AMZN, COMID, ZOOID } from '../constants/steps-config';

export const getBusinessUnit = businessUnit => {
  switch (businessUnit) {
    case ZOOID:
    case COMID:
      return businessUnit;
    default:
      return AMZN;
  }
};

export const isReadOnly = surveyDetail => {
  const surveyStatus = get(surveyDetail, 'surveyStatus', null);
  return surveyStatus === 'Submitted';
};
