import { forEach, get, isEmpty } from 'lodash';
import { getAttestationContent } from '../../components/Submit/utils/attestations_list';
import { getSubmitAttestationConfig } from '../../../../common/constants/surveyType';

const isAttestationPresent = (submit, attestation) => {
  let isPresent = false;
  forEach(submit, val => {
    if (val === attestation) isPresent = true;
  });
  return isPresent;
};

const validateSubmit = (surveyId, submitAttestation, surveyType) => {
  const attestationConfig = getSubmitAttestationConfig(surveyType);

  const submit = submitAttestation.data;
  if (isEmpty(submit)) return false;
  if (
    isAttestationPresent(
      submit,
      getAttestationContent(attestationConfig.attestationEmployeeListId, surveyType),
    ) &&
    isEmpty(get(submitAttestation, 'textbox'))
  )
    return false;

  return true;
};

export default validateSubmit;
