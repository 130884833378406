import { get, join, map, size, set, isEmpty } from 'lodash';
import {
  GET_ACTIVITIES_ENDPOINT,
  GET_EMPLOYEES_FOR_SURVEY_ENDPOINT,
  GET_PROJECT_DOCUMENTS,
  GET_VENDORS_FOR_SURVEY_ENDPOINT,
  SEARCH_POPULATION_ENDPOINT,
  SEARCH_PROJECT_ENDPOINT,
  VALARI_API,
} from '../../../../../../../../common/config/api_endpoints';
import addContextToPayload from '../../../../../../../../common/utils/api_util';
import { ITEM_TYPE_LABOR } from '../../../../../../../../common/config/api_constants';
import { getSerializedData } from '../../../../../../../survey_page/utils/redux_payload_utils';
import { SURVEY_TYPE } from '../../../../../../../../common/constants/surveyType';

const parser = data => {
  try {
    return JSON.parse(data);
  } catch (e) {
    return null;
  }
};

export const getSurveyType = data => {
  if (get(data, 'surveyType', ' ') === SURVEY_TYPE.CONTRACT) {
    return SURVEY_TYPE.CONTRACT;
  }
  return SURVEY_TYPE.LABOR;
};

export const getProjectDescription = (data, surveyType) => {
  switch (surveyType) {
    case SURVEY_TYPE.LABOR:
    default:
      return `Select all projects you and/or your employees worked on in
       ${data.studyPeriod}. You can add project(s) if you cannot find your 
       team’s project(s) in the list.`;

    case SURVEY_TYPE.CONTRACT:
      return `Select all projects vendors worked on in ${data.studyPeriod}. 
      You can add project(s) if you cannot find in the list.`;
  }
};

export const getActivityDescription = (data, surveyType) => {
  switch (surveyType) {
    case SURVEY_TYPE.LABOR:
    default:
      return `Select all projects you and/or your employees worked 
      on in ${data.studyPeriod}. You can add project(s) if you 
      cannot find your team’s project(s) in the list.`;

    case SURVEY_TYPE.CONTRACT:
      return `Select all activities vendors worked on in ${data.studyPeriod}.
         You can add activities if you cannot find in the list.`;
  }
};

const transformReceivedEmployees = ({ employeeAlias, formattedEmployeeName, jobTitle }) => {
  return { employeeAlias, formattedEmployeeName, jobTitle };
};

const getSecondaryAssigneeDetails = async (clientId, employees, studyPeriod) => {
  let secondaryAssigneeDetails = [];
  const query = join(
    map(employees, employee => {
      return `recordId equals ${employee}`;
    }),
    ' || ',
  );

  if (size(employees) > 0) {
    await addContextToPayload(VALARI_API, SEARCH_POPULATION_ENDPOINT, studyPeriod, {
      body: {
        clientId,
        itemType: ITEM_TYPE_LABOR,
        pageSize: 20,
        pageNumber: 1,
        query,
      },
    }).then(r => {
      secondaryAssigneeDetails = map(getSerializedData(get(r, 'body.data', [])), res =>
        transformReceivedEmployees(res),
      );
    });
  }

  return secondaryAssigneeDetails;
};

const getAdditionalSurveyData = async (item, studyPeriod, searchProjectQuery) => {
  const promises = [
    addContextToPayload(VALARI_API, GET_ACTIVITIES_ENDPOINT, studyPeriod, {
      body: {
        surveyType: item.surveyType,
      },
    }),
    addContextToPayload(VALARI_API, GET_EMPLOYEES_FOR_SURVEY_ENDPOINT, studyPeriod, {
      body: {
        surveyId: item.surveyId,
        clientId: item.clientId,
      },
    }),
    addContextToPayload(VALARI_API, GET_VENDORS_FOR_SURVEY_ENDPOINT, studyPeriod, {
      body: {
        surveyId: item.surveyId,
        clientId: item.clientId,
      },
    }),
    addContextToPayload(VALARI_API, GET_PROJECT_DOCUMENTS, studyPeriod, {
      body: {
        projectIdList: map(
          get(parser(get(item, 'userResponse', '{}')), 'Project.data.selectedProjects', []),
          ({ id }) => id,
        ),
        surveyId: item.surveyId,
      },
    }),
  ];
  if (!isEmpty(searchProjectQuery)) {
    promises.push(
      addContextToPayload(VALARI_API, SEARCH_PROJECT_ENDPOINT, studyPeriod, {
        body: {
          clientId: item.clientId,
          query: searchProjectQuery,
        },
      }),
    );
  }
  const response = await Promise.all(promises)
    .then(r => {
      return r;
    })
    .catch(() => {
      return [];
    });

  return response;
};

const parseProjectData = data => {
  const projectData = {};
  JSON.parse(data).forEach(project => {
    set(projectData, project.projectId, project);
  });
  return projectData;
};

const getSurveyData = async (item, studyPeriod) => {
  const userResponse = parser(get(item, 'userResponse', {}));
  const selectedProjects = get(userResponse, 'Project.data.selectedProjects', []);
  let searchProjectQuery = '';
  selectedProjects.forEach(({ id }) => {
    searchProjectQuery += `projectId equals ${id} || `;
  });
  const response = await getAdditionalSurveyData(item, studyPeriod, searchProjectQuery);
  const secondaryAssigneeDetails = await getSecondaryAssigneeDetails(
    item.clientId,
    item.secondaryAssignees,
    studyPeriod,
  );
  if (isEmpty(response)) {
    return null;
  }
  return {
    ...item,
    activityList: get(response, [0, 'body', 'activityList'], []),
    userResponse,
    employeeData: parser(get(response, [1, 'employeeData'], [])),
    vendorData: parser(get(response, [2, 'vendorData'], [])),
    projectDocumentation: parser(get(response[3], 'body.projectIdToDocumentDataMap', '{}')),
    studyPeriod: item.year,
    surveyName: `${item.surveyType} Survey-${item.userName}`,
    surveyGroupId: item.businessUnit,
    secondaryAssigneeDetails,
    searchProjectsResponse: parseProjectData(get(response, [4, 'body', 'data'], '[]')),
  };
};

export default getSurveyData;
