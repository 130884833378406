import { Header, SpaceBetween, Table as PolarisTable } from '@amzn/awsui-components-react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import getColumnDefinitions from '../utils';
import TextFiltering from './TextFiltering';

const Table = props => {
  const { config, data, schema, filterOn } = props;
  const { dataTrackingId, dataLoadingText, emptyElement } = config;
  const [visibleItem, setVisibleItem] = useState(data);
  useEffect(() => {
    setVisibleItem(data);
  }, [data]);
  const getHeader = () => {
    const headerConfig = get(props, ['config', 'headerConfig'], '');
    const { title, Actions, description } = headerConfig;
    return (
      <Header
        counter={`(${data.length})`}
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            {Actions}
          </SpaceBetween>
        }
        description={description}
      >
        {title}
      </Header>
    );
  };
  return (
    <PolarisTable
      columnDefinitions={getColumnDefinitions(schema)}
      items={visibleItem}
      trackBy={dataTrackingId}
      loadingText={dataLoadingText}
      sortingColumn="project"
      stickyHeader
      empty={emptyElement}
      filter={
        <TextFiltering
          setVisibleItems={setVisibleItem}
          filterOn={filterOn}
          data={data}
          filteringPlaceholder="Search documents"
        />
      }
      header={getHeader()}
    />
  );
};

Table.propTypes = {
  config: PropTypes.object.isRequired,
  schema: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  filterOn: PropTypes.array.isRequired,
};

export default Table;
