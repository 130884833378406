import React, { useState } from 'react';
import {
  Box,
  Button,
  ColumnLayout,
  Container,
  Header,
  Link,
  SpaceBetween,
  Textarea,
  Alert,
} from '@amzn/awsui-components-react';
import PropTypes from 'prop-types';
import { get, map, size } from 'lodash';
import { connect } from 'react-redux';
import ReassignModal from './ReassignModal';
import PhoneToolTiles from './PhoneToolTiles';
import SecondaryAssignee from '../../SecondaryAssignee/SecondaryAssignee';
import DocLink from '../../../../../common/components/doc_link/utils/DocLink';
import getDocById from '../../../../../common/components/doc_link/utils/getDocById';
import FAQ from '../../../../../common/constants/documentIds';
import { isReadOnly } from '../../../utils/survey_page_utils';
import '../css/Employee.css';

const Employee = ({
  state,
  setState,
  pageContents,
  setPageElements,
  surveyContext,
  surveyId,
  surveyType,
  surveyDetails,
}) => {
  const userResponse = get(surveyContext, [surveyId, 'userResponse'], '{}');
  const [isReassignModalVisible, setIsReassignModalVisible] = useState(false);
  const employeeList = get(pageContents, 'Employee.employeeList', []);
  const showReassignModal = () => {
    setIsReassignModalVisible(true);
  };
  const hideReassignModal = () => {
    setIsReassignModalVisible(false);
  };

  const primaryHeader = () => {
    return (
      <Header
        counter={`(${size(employeeList)})`}
        variant="h2"
        info={<DocLink pdf={getDocById(FAQ)} text="Why are they assigned to me?" variant="info" />}
      >
        Employees
      </Header>
    );
  };

  const textBoxHandler = ({ detail: { value } }) => {
    const truncatedValue = size(value) > 5000 ? value.substr(0, 5000) : value;
    setState({
      key: 'Employee',
      value: {
        ...state.Employee,
        data: {
          ...state.Employee.data,
          textBoxContent: truncatedValue,
        },
      },
    });
  };

  return (
    <div className="employee-review-page">
      <SpaceBetween size="l">
        <Alert
          statusIconAriaLabel="Info"
          action={
            <Button onClick={showReassignModal} disabled={isReadOnly(surveyDetails)}>
              Reassign the survey
            </Button>
          }
          header="Not the right person to complete the survey?"
        >
          {/* eslint-disable-next-line max-len */}
          {`You can reassign the survey to another person who you think can provide details on the employees’ time spent on ${get(
            surveyDetails,
            'year',
            '',
          )} projects and activities. ` +
            "It is recommended you confirm with the person you're reassigning. " +
            'Once the survey is reassigned, you no longer have access to this survey.'}
        </Alert>
        <Container header={primaryHeader()}>
          <SpaceBetween size="m">
            <Box color="text-body-secondary">
              You’ll be asked the amount of time each employee spent on various projects and
              activities.
            </Box>
            <ColumnLayout columns={2}>
              {map(employeeList, profile => {
                return <PhoneToolTiles {...profile} />;
              })}
            </ColumnLayout>
            <SpaceBetween size="xxxs">
              <Box color="text-body-secondary">
                Need to add or remove an employee from your survey?
              </Box>
              <Box color="text-body-secondary">
                Contact <Link href="mailto:ukrdtax@amazon.com">ukrdtax@amazon.com</Link>
              </Box>
            </SpaceBetween>
            <SpaceBetween size="xxxs">
              <Box variant="p">
                Describe the overall function of your team in 1-2 sentences (max 5000 characters).
              </Box>
              <Textarea
                onChange={textBoxHandler}
                value={get(state, 'Employee.data.textBoxContent', '')}
                disabled={isReadOnly(surveyDetails)}
              />
            </SpaceBetween>
          </SpaceBetween>
        </Container>
        <SecondaryAssignee
          state={state}
          pageContents={pageContents}
          setPageContents={setPageElements}
          userResponse={userResponse}
        />

        <ReassignModal
          onCancel={hideReassignModal}
          visibility={isReassignModalVisible}
          onConfirm={hideReassignModal}
          surveyType={surveyType}
        />
      </SpaceBetween>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    surveyId: get(state, 'router.location.state.headerDetails.surveyId'),
    surveyContext: get(state, 'entities.surveyDetails.data'),
  };
};

Employee.propTypes = {
  state: PropTypes.object.isRequired,
  setState: PropTypes.func.isRequired,
  pageContents: PropTypes.object.isRequired,
  setPageElements: PropTypes.func.isRequired,
  surveyId: PropTypes.string.isRequired,
  surveyContext: PropTypes.object.isRequired,
  surveyType: PropTypes.string.isRequired,
  surveyDetails: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, null)(Employee);
