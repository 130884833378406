import { Auth } from 'aws-amplify';
import { get, set } from 'lodash';
import {
  createAPILifecycleActions,
  createAPILifecycleActionTypes,
} from '../../../../../redux/actions/action_utils';
import createAmplifyAPICallEpic from '../../../../../redux/epics/utils';
import { REASSIGN_SURVEY_ENDPOINT } from '../../../../../common/config/api_endpoints';

export const REASSIGN_SURVEY_TYPES = createAPILifecycleActionTypes('REASSIGN_SURVEY_TYPES');
export const reassignSurveyAction = createAPILifecycleActions(REASSIGN_SURVEY_TYPES.NAME);

const initialState = {
  isFetching: false,
  data: null,
  error: null,
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case REASSIGN_SURVEY_TYPES.BEGIN:
      return {
        ...state,
        isFetching: true,
      };
    case REASSIGN_SURVEY_TYPES.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: payload,
        error: null,
      };
    case REASSIGN_SURVEY_TYPES.FAILURE:
      return {
        ...state,
        isFetching: false,
        data: null,
        error: payload,
      };

    case REASSIGN_SURVEY_TYPES.CLEAR:
      return initialState;
    default:
      return state;
  }
};

const payloadGenerator = async ({ originalPayload }) => {
  let newPayload = originalPayload;
  if (!newPayload) {
    newPayload = {};
  }
  const requestAttributes = get((await Auth.currentSession()).getIdToken(), 'payload', {});
  set(newPayload, ['body', 'userAlias'], get(requestAttributes, ['identities', 0, 'userId']));
  return newPayload;
};

export const reassignSurveyEpic = createAmplifyAPICallEpic({
  actionType: REASSIGN_SURVEY_TYPES.BEGIN,
  api: REASSIGN_SURVEY_ENDPOINT,
  payloadGenerator,
  successCallback: response => [reassignSurveyAction.SUCCESS(response)],
  errorCallback: () => [reassignSurveyAction.FAILURE()],
});
