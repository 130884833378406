import {
  createAPILifecycleActions,
  createAPILifecycleActionTypes,
} from '../../../redux/actions/action_utils';
import createAmplifyAPICallEpic from '../../../redux/epics/utils';
import { SEARCH_POPULATION_ENDPOINT } from '../../../common/config/api_endpoints';
import { extractTableDataFromPayload } from '../utils/redux_payload_utils';

const SEARCH_POPULATION_ACTION_TYPES = createAPILifecycleActionTypes(
  'SEARCH_POPULATION_ACTION_TYPES',
);
export const searchPopulationActions = createAPILifecycleActions(
  SEARCH_POPULATION_ACTION_TYPES.NAME,
);

const initialState = {
  isFetching: false,
  data: null,
  error: null,
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SEARCH_POPULATION_ACTION_TYPES.BEGIN:
      return {
        ...state,
        isFetching: true,
      };
    case SEARCH_POPULATION_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: {
          ...state.data,
          ...extractTableDataFromPayload(payload),
        },
        error: null,
      };
    case SEARCH_POPULATION_ACTION_TYPES.FAILURE:
      return {
        ...state,
        isFetching: false,
        data: null,
        error: payload,
      };

    case SEARCH_POPULATION_ACTION_TYPES.CLEAR:
      return initialState;
    default:
      return state;
  }
};

export const searchPopulationEpic = createAmplifyAPICallEpic({
  actionType: SEARCH_POPULATION_ACTION_TYPES.BEGIN,
  api: SEARCH_POPULATION_ENDPOINT,
  successCallback: response => [searchPopulationActions.SUCCESS(response.body)],
  errorCallback: () => [searchPopulationActions.FAILURE()],
});
