import React, { useEffect, useState } from 'react';
import axios from 'axios';
import produce from 'immer';
import {
  Box,
  Button,
  SpaceBetween,
  Modal,
  FormField,
  Input,
  Select,
  Textarea,
  Tiles,
  Icon,
} from '@amzn/awsui-components-react';
import { get, isUndefined } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { bindActionCreators } from 'redux';
import {
  VALARI_API,
  GET_PRESIGNED_URL,
  CREATE_PROJECT_DOCUMENT,
  GET_PROJECT_DOCUMENTS,
  EDIT_PROJECT_DOCUMENT,
  DELETE_PROJECT_DOCUMENT,
} from '../../../../../common/config/api_endpoints';
import '../style.css';
import {
  documentTypes,
  projectDocumentTableSchema,
  projectDocumentTableFilterOn,
} from '../constants';
import addContextToPayload from '../../../../../common/utils/api_util';
import { getStudyPeriod } from '../../../../../common/constants/study_period';
import Table from '../../../../../common/components/table/components/Table';
import { setPageNotification } from '../../../../../common/components/with_page/redux/reducer';
import {
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_INFO,
  NOTIFICATION_TYPE_SUCCESS,
} from '../../../../../common/components/with_page/notifications/constants';
import { isReadOnly } from '../../../utils/survey_page_utils';

const ProjectDocuments = ({
  state,
  setState,
  surveyId,
  studyPeriod,
  pushNotification,
  surveyDetails,
  derivedClientId,
}) => {
  const documentsInitialState = {
    modalState: false,
    documentType: {},
    uploadType: 'file',
    uploadDocument: null,
    uploadLink: null,
    documentDescription: '',
    selectedProject: null,
    isRequesting: false,
    selectedDocument: null,
    uploadDocumentError: null,
    selectedProjectOption: {},
    deleteModalState: false,
    projectId: null,
    documentId: null,
    isDocumentSelected: false,
  };
  const [documentsState, setDocumentsState] = useState(documentsInitialState);

  const selectedProjects = get(state, 'Project.data.selectedProjects', []);

  useEffect(() => {
    if (!state.ProjectDocuments) {
      setState({
        key: 'ProjectDocuments',
        value: {},
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getProjectDocuments = async () => {
    try {
      const request = {
        body: {
          projectIdList: selectedProjects.map(project => project.id),
          surveyId,
        },
      };
      const response = await addContextToPayload(
        VALARI_API,
        GET_PROJECT_DOCUMENTS,
        studyPeriod,
        request,
      );
      if (response.status !== 200) throw response;

      const projectDocuments = JSON.parse(get(response, 'body.projectIdToDocumentDataMap'));
      setState({
        key: 'ProjectDocuments',
        value: {
          data: projectDocuments,
          error: null,
        },
      });
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    getProjectDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(selectedProjects)]);

  const openModal = event => {
    // eslint-disable-next-line no-param-reassign
    event.cancelBubble = true;
    setDocumentsState(prevState => {
      return {
        ...prevState,
        modalState: true,
      };
    });
  };
  const openDeleteModal = (event, projectId, documentId) => {
    // eslint-disable-next-line no-param-reassign
    event.cancelBubble = true;
    setDocumentsState(prevState => {
      return {
        ...prevState,
        deleteModalState: true,
        projectId,
        documentId,
      };
    });
  };
  const getProjectList = () => {
    return selectedProjects.map(project => {
      return {
        label: project.name,
        value: project.id,
      };
    });
  };

  const editDocument = async (projectId, documentId) => {
    const allDocuments = get(state, ['ProjectDocuments', 'data', projectId], []);
    const documentDetails = allDocuments.find(
      document => document.projectDocumentId === documentId,
    );
    const allProjects = getProjectList();
    const {
      documentSubCategory,
      documentFileName,
      documentLink,
      documentDescription,
      presignedURL,
    } = documentDetails;
    let file;
    let documentSize = 0;
    if (documentFileName) {
      file = await axios.get(presignedURL);
      documentSize = get(file, 'headers.content-length', 0);
    }
    setDocumentsState(prevState => {
      return {
        ...prevState,
        documentType: { label: documentSubCategory, value: documentSubCategory },
        uploadType: documentFileName ? 'file' : 'link',
        uploadLink: documentLink,
        documentDescription,
        projectId,
        selectedProjectOption: allProjects.find(project => project.value === projectId),
        selectedDocument: documentDetails,
        modalState: true,
        uploadDocument: documentFileName
          ? {
              data: file.data,
              size: documentSize,
              name: documentFileName,
            }
          : {},
      };
    });
  };

  const uploadFile = async selectedDocument => {
    const { uploadDocument } = documentsState;
    const request = {
      body: {
        clientId: derivedClientId,
      },
    };
    if (selectedDocument) request.documentId = selectedDocument.projectDocumentId;
    try {
      let response = await addContextToPayload(VALARI_API, GET_PRESIGNED_URL, studyPeriod, request);

      const { documentId, presignedUrl } = response.body;

      response = await axios.put(presignedUrl, uploadDocument);
      return documentId;
    } catch (error) {
      throw error;
    }
  };

  const getPayload = async documentId => {
    const {
      uploadType,
      uploadDocument,
      projectId,
      documentType,
      uploadLink,
      selectedDocument,
      documentDescription,
      selectedProjectOption,
    } = documentsState;
    const request = {
      documentSubCategory: documentType.value,
      projectId: selectedProjectOption.value,
      documentUploadType: 'SHARE_DOCUMENT_LINK',
      documentDescription,
      surveyId,
      oldProjectId: projectId,
      clientId: derivedClientId,
    };

    if (uploadType === 'file') {
      const fileTypes = uploadDocument.name.split('.');
      return {
        ...request,
        documentId,
        documentFileName: uploadDocument.name,
        documentFileType: uploadDocument.type
          ? uploadDocument.type
          : fileTypes[fileTypes.length - 1],
        documentUploadType: 'UPLOAD_DOCUMENT',
      };
    }
    return selectedDocument
      ? {
          ...request,
          documentLink: uploadLink,
          documentId: selectedDocument.projectDocumentId,
        }
      : {
          ...request,
          documentLink: uploadLink,
        };
  };

  const addDocument = async () => {
    const {
      uploadType,
      selectedProjectOption,
      selectedDocument,
      isDocumentSelected,
      projectId,
    } = documentsState;
    const selectedProjectId = selectedProjectOption.value;
    let newState = {};
    let documentId;
    try {
      setDocumentsState(prevState => {
        return {
          ...prevState,
          isRequesting: true,
        };
      });
      if (uploadType === 'file') {
        documentId = isDocumentSelected
          ? await uploadFile(selectedDocument)
          : selectedDocument.projectDocumentId;
      }

      const projectDocumentId = documentId;
      const request = await getPayload(projectDocumentId);
      if (selectedDocument) {
        request.oldDocumentId = selectedDocument.projectDocumentId;
        delete request.documentId;

        request.newDocumentId = projectDocumentId;
        // edit case
        const response = await addContextToPayload(VALARI_API, EDIT_PROJECT_DOCUMENT, studyPeriod, {
          body: request,
        });
        const data = JSON.parse(get(response, 'body.data'), JSON.stringify({}));

        newState = produce(state.ProjectDocuments, draft => {
          // eslint-disable-next-line no-param-reassign
          draft.data[projectId] = draft.data[projectId].filter(document => {
            return document.projectDocumentId !== selectedDocument.projectDocumentId;
          });
        });
        newState = produce(newState, draft => {
          draft.data[selectedProjectId].push(data);
        });
      } else {
        // add case
        const response = await addContextToPayload(
          VALARI_API,
          CREATE_PROJECT_DOCUMENT,
          studyPeriod,
          {
            body: request,
          },
        );
        const data = JSON.parse(get(response, 'body.data'), JSON.stringify({}));

        newState = produce(state.ProjectDocuments, draft => {
          draft.data[selectedProjectId].push(data);
        });
      }

      setState({
        key: 'ProjectDocuments',
        value: newState,
      });

      setDocumentsState({
        ...documentsInitialState,
      });
      pushNotification({
        type: NOTIFICATION_TYPE_SUCCESS,
        content: 'Document added successfully.',
        id: uuidv4(),
      });
    } catch (error) {
      setDocumentsState({
        ...documentsInitialState,
      });
      pushNotification({
        type: NOTIFICATION_TYPE_ERROR,
        content: 'Document cannot be added.',
        id: uuidv4(),
      });
    }
  };

  // delete method
  const deleteDocument = async () => {
    const { projectId, documentId } = documentsState;
    const allDocuments = get(state, ['ProjectDocuments', 'data', projectId], []);
    const documentDetails = allDocuments.find(
      document => document.projectDocumentId === documentId,
    );
    let documentName = '';
    if (!isUndefined(documentDetails)) {
      if (!documentDetails.documentFileName) {
        documentName = documentDetails.documentLink;
      } else {
        documentName = documentDetails.documentFileName;
      }
    }
    const request = {
      projectId,
      documentId,
      clientId: derivedClientId,
    };
    try {
      await addContextToPayload(VALARI_API, DELETE_PROJECT_DOCUMENT, studyPeriod, {
        body: request,
      });
      const newState = produce(state.ProjectDocuments, draft => {
        // eslint-disable-next-line no-param-reassign
        draft.data[projectId] = draft.data[projectId].filter(document => {
          return document.projectDocumentId !== documentId;
        });
      });
      setState({
        key: 'ProjectDocuments',
        value: newState,
      });

      setDocumentsState({
        ...documentsInitialState,
      });
      pushNotification({
        type: NOTIFICATION_TYPE_INFO,
        content: (
          <>
            <b>{documentName}</b> has been deleted.
          </>
        ),
        id: uuidv4(),
      });
    } catch (error) {
      setDocumentsState({
        ...documentsInitialState,
      });
      pushNotification({
        type: NOTIFICATION_TYPE_ERROR,
        content: 'Error in deleting document.',
        id: uuidv4(),
      });
    }
  };

  const closeModal = () => {
    setDocumentsState(documentsInitialState);
  };

  const handleFileInput = event => {
    const file = event.target.files[0];
    // eslint-disable-next-line no-param-reassign
    event.target.value = null;
    if (file.size > 15 * 1e6) {
      setDocumentsState(prevState => {
        return {
          ...prevState,
          uploadDocument: null,
          uploadDocumentError:
            'The selected file exceeds the maximum file size you can upload. Select another file.',
          isDocumentSelected: false,
        };
      });
    } else {
      setDocumentsState(prevState => {
        return {
          ...prevState,
          uploadDocument: file,
          uploadDocumentError: null,
          isDocumentSelected: true,
        };
      });
    }
  };

  const updateState = (key, value) => {
    setDocumentsState(prevState => {
      return {
        ...prevState,
        [key]: value,
      };
    });
  };

  const isDisabledButton = () => {
    return (
      !documentsState.documentType.value ||
      (!documentsState.uploadDocument && !documentsState.uploadLink) ||
      !documentsState.selectedProjectOption.value
    );
  };

  const getClickableLink = link => {
    return link.startsWith('http://') || link.startsWith('https://') ? link : `//${link}`;
  };

  const downloadFile = async (event, item) => {
    event.preventDefault();
    try {
      if (item.documentFileName) {
        const response = await axios.get(item.presignedURL, { responseType: 'blob' });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', item.documentFileName);
        document.body.appendChild(link);
        link.click();
      } else {
        const link = document.createElement('a');
        link.href = getClickableLink(item.documentLink);
        link.setAttribute('target', '_blank');
        document.body.appendChild(link);
        link.click();
      }
    } catch (e) {
      setDocumentsState({
        ...documentsInitialState,
      });
      pushNotification({
        type: NOTIFICATION_TYPE_ERROR,
        content: 'Document cannot be downloaded.',
        id: uuidv4(),
      });
    }
  };

  const projectDocumentsState = state.ProjectDocuments;

  const getDocumentLink = item => {
    return (
      // eslint-disable-next-line
      <a href="#" role="link" onClick={event => downloadFile(event, item)}>
        {item.documentFileName || item.documentLink}
      </a>
    );
  };
  const getDocumentActions = (projectId, item) => {
    return (
      <div>
        <Button
          iconName="edit"
          variant="icon"
          onClick={() => editDocument(projectId, item.projectDocumentId)}
          disabled={isReadOnly(surveyDetails)}
        />
        <Button
          iconName="close"
          variant="icon"
          onClick={event => openDeleteModal(event, projectId, item.projectDocumentId)}
          disabled={isReadOnly(surveyDetails)}
        />
      </div>
    );
  };
  const getDocuments = () => {
    const documents = [];
    // eslint-disable-next-line array-callback-return
    selectedProjects.map(project => {
      const projectDocuments = get(projectDocumentsState, ['data', project.id], []);
      // eslint-disable-next-line array-callback-return
      projectDocuments.map(projectDocument => {
        const document = {
          project: project.name,
          type: projectDocument.documentSubCategory,
          document: getDocumentLink(projectDocument),
          actions: getDocumentActions(project.id, projectDocument),
          documentFileName: projectDocument.documentFileName || projectDocument.documentLink,
        };
        documents.push(document);
      });
    });
    return documents;
  };

  const closeDeleteModal = () => {
    setDocumentsState(documentsInitialState);
  };
  const getDeleteModal = () => {
    const { projectId, documentId } = documentsState;
    const allDocuments = get(state, ['ProjectDocuments', 'data', projectId], []);
    const documentDetails = allDocuments.find(
      document => document.projectDocumentId === documentId,
    );
    let documentName = '';
    if (!isUndefined(documentDetails)) {
      if (!documentDetails.documentFileName) {
        documentName = documentDetails.documentLink;
      } else {
        documentName = documentDetails.documentFileName;
      }
    }
    return (
      <Modal
        onDismiss={closeDeleteModal}
        visible={documentsState.deleteModalState}
        closeAriaLabel="Close modal"
        size="medium"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link" onClick={closeDeleteModal}>
                Cancel
              </Button>
              <Button onClick={deleteDocument}>Delete document</Button>
            </SpaceBetween>
          </Box>
        }
        header="Delete document"
      >
        <div>
          Are you sure you want to delete <b>{documentName}</b>?
        </div>
      </Modal>
    );
  };

  const getModal = () => {
    return (
      <Modal
        onDismiss={closeModal}
        visible={documentsState.modalState}
        closeAriaLabel="Close modal"
        size="medium"
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link" onClick={closeModal} disabled={documentsState.isRequesting}>
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={addDocument}
                disabled={documentsState.isRequesting || isDisabledButton()}
              >
                {documentsState.isRequesting ? 'In progress' : 'Upload'}
              </Button>
            </SpaceBetween>
          </Box>
        }
        header={documentsState.isEditRequest ? 'Edit project document' : 'Upload project document'}
      >
        <FormField description="Select which project this document belong to" label="Project">
          <Select
            selectedOption={documentsState.selectedProjectOption}
            onChange={({ detail }) => updateState('selectedProjectOption', detail.selectedOption)}
            options={getProjectList()}
          />
        </FormField>
        <br />
        <FormField
          description="Select the type that is the most relevant to the document to be added."
          label="Document type"
        >
          <Select
            selectedOption={documentsState.documentType}
            onChange={({ detail }) => updateState('documentType', detail.selectedOption)}
            options={documentTypes}
          />
        </FormField>
        <br />
        <SpaceBetween size="s">
          <div>
            <b>Upload method</b>
          </div>
          <Tiles
            onChange={({ detail }) => updateState('uploadType', detail.value)}
            value={documentsState.uploadType}
            items={[
              { label: 'Upload file', value: 'file' },
              { label: 'Provide link', value: 'link' },
            ]}
            columns={1}
          />
          {documentsState.uploadType === 'file' ? (
            <div className="margin-top-10">
              <Button iconName="upload" className="polarisInputButton">
                <input className="inputButton hidden" type="file" onChange={handleFileInput} />
                Upload file
              </Button>
              <div style={{ color: 'grey', fontSize: '12px' }}>Maximum upload file size: 15MB</div>

              {documentsState.uploadDocument ? (
                <div>
                  <br />
                  <div>
                    <Icon name="status-positive" size="small" variant="success" />{' '}
                    {get(documentsState, 'uploadDocument.name')}
                  </div>
                  <div style={{ color: 'grey', fontSize: '12px' }}>
                    {Number(get(documentsState, 'uploadDocument.size', 0) / 1000, 1).toFixed(1)} Kb
                  </div>
                </div>
              ) : (
                <div style={{ color: 'red', fontSize: '12px' }}>
                  {get(documentsState, 'uploadDocumentError')}
                </div>
              )}
            </div>
          ) : (
            <div className="margin-top-10">
              <FormField
                description={`It's recommended to provide a link 
              without restricted access to avoid follow-up requests.`}
                label="Document Link"
              >
                <Input
                  type="text"
                  value={documentsState.uploadLink}
                  onChange={({ detail }) => updateState('uploadLink', detail.value)}
                />
              </FormField>
            </div>
          )}
        </SpaceBetween>
        <div className="margin-top-10">
          <FormField
            label={
              <div>
                Document description - <i>optional</i>
              </div>
            }
          >
            <Textarea
              onChange={({ detail }) => updateState('documentDescription', detail.value)}
              value={documentsState.documentDescription}
              placeholder=""
            />
          </FormField>
        </div>
      </Modal>
    );
  };

  const getAddDocumentButton = () => {
    return (
      <Button
        onClick={event => {
          openModal(event);
        }}
        disabled={isReadOnly(surveyDetails)}
      >
        Add documentation
      </Button>
    );
  };

  const getEmptyTableView = () => {
    return (
      <Box textAlign="center" color="inherit" padding={{ horizontal: 'l', vertical: 'l' }}>
        <Box padding={{ bottom: 's' }} variant="p" color="inherit">
          No project document has been added.
        </Box>
        {getAddDocumentButton()}
      </Box>
    );
  };

  return (
    <SpaceBetween size="m">
      {getModal()}
      {getDeleteModal()}
      {
        <Table
          schema={projectDocumentTableSchema}
          data={getDocuments()}
          filterOn={projectDocumentTableFilterOn}
          config={{
            dataTrackingId: 'documentTable',
            dataLoadingText: 'Loading documents.',
            emptyElement: getEmptyTableView(),
            headerConfig: {
              title: 'Documents',
              Actions: getAddDocumentButton(),
            },
          }}
        ></Table>
      }
    </SpaceBetween>
  );
};
ProjectDocuments.propTypes = {
  state: PropTypes.object.isRequired,
  setState: PropTypes.func.isRequired,
  surveyId: PropTypes.string.isRequired,
  studyPeriod: PropTypes.string.isRequired,
  pushNotification: PropTypes.func.isRequired,
  surveyDetails: PropTypes.object.isRequired,
  derivedClientId: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  return {
    surveyId: get(state, 'router.location.state.headerDetails.surveyId'),
    studyPeriod: getStudyPeriod(state),
    derivedClientId: get(state, 'derivedClientId.derivedClientId', undefined),
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      pushNotification: setPageNotification,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDocuments);
