import React, { useEffect } from 'react';
import { Box, Grid, SpaceBetween } from '@amzn/awsui-components-react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PrimaryAssigneeSurveys from '../../common/components/primary_assignee/PrimaryAssigneeSurveys';
import SecondaryAssigneeSurveys from '../../common/components/secondary_assginee/SecondaryAssigneeSurveys';
import SurveySubmittedAlert from '../../common/components/survey_submitted_alert/SurveySubmittedAlert';
import { getSurveysAction } from '../../common/redux/GetSurveys';

const Content = ({ getSurveys, surveys, surveyDetails, isSubmitted, submittedSurveyId }) => {
  useEffect(
    () => {
      getSurveys();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Box margin={{ bottom: 'l' }}>
      <Box margin={{ top: 's' }} padding={{ top: 'xxl', horizontal: 's' }}>
        <Grid
          gridDefinition={[
            { colspan: { xl: 8, l: 8, s: 10, xxs: 10 }, offset: { l: 2, xxs: 1 } },
            { colspan: { xl: 2, l: 3, s: 4, xxs: 10 }, offset: { s: 0, xxs: 1 } },
          ]}
        >
          <div className="custom-home-main-content-area">
            <SpaceBetween size="s">
              {isSubmitted && (
                <SurveySubmittedAlert
                  surveyDetails={surveyDetails}
                  submittedSurveyId={submittedSurveyId}
                />
              )}
              <PrimaryAssigneeSurveys surveys={surveys} surveyDetails={surveyDetails} />
              <SecondaryAssigneeSurveys surveys={surveys} surveyDetails={surveyDetails} />
            </SpaceBetween>
          </div>
        </Grid>
      </Box>
    </Box>
  );
};

Content.propTypes = {
  surveys: PropTypes.object.isRequired,
  surveyDetails: PropTypes.object.isRequired,
  isSubmitted: PropTypes.bool,
  getSurveys: PropTypes.func.isRequired,
  submittedSurveyId: PropTypes.string,
};

const mapStateToProps = state => ({
  surveys: get(state, 'entities.surveys.data', {}) || {},
  surveyDetails: get(state, 'entities.surveyDetails.data', {}) || {},
  isSubmitted: get(state, 'router.location.state.submitted'),
  submittedSurveyId: get(state, 'router.location.state.surveyId'),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getSurveys: getSurveysAction.BEGIN,
    },
    dispatch,
  );

Content.defaultProps = {
  submittedSurveyId: '',
};

export default connect(mapStateToProps, mapDispatchToProps)(Content);
