// Each page has an associated pagePublisher - MetricsUtility.js
export const METRICS_PAGE = {
  SURVEY: 'SURVEY_PAGE',
};

// Each action has an associated methodPublisher - MetricsUtility.js
export const METRICS_METHOD = {
  SURVEY_WIZARD: 'SURVEY_WIZARD_METHOD',
};

// Each timer has an associated timerMetric - MetricsUtility.js
export const METRICS_TIMER = {
  SURVEY_STEP: 'SURVEY_STEP_TIMER',
};

export const METRICS_NAME = {
  SURVEY_ID: 'surveyId',
};
