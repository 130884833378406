import React from 'react';
import { Input, Icon, Table, SpaceBetween, Button } from '@amzn/awsui-components-react';
import PropTypes from 'prop-types';
import './AllocationGrid.css';
import { round } from 'lodash/math';
import { getEntityDetailsKey, SURVEY_TYPE } from '../../../../common/constants/surveyType';
import { isReadOnly } from '../../utils/survey_page_utils';

const AllocationGrid = props => {
  const {
    rowsEntity,
    colsEntity,
    gridValues,
    setGridValues,
    allocationType,
    surveyType,
    surveyDetails,
    entityForActivityAllocation,
    copiedValues,
    copiedColumn,
    setCopiedValues,
  } = props;

  const resetCopiedValues = () => {
    setCopiedValues({}, undefined);
  };

  function getTotalForArray(values) {
    if (!values) {
      return '0';
    }
    return Object.keys(values)
      .reduce((partialSum, key) => {
        if (key === 'total') {
          return partialSum;
        }
        return partialSum + Number(values[key]);
      }, 0)
      .toString();
  }

  const createUpdatedGridObject = (colEntity, rowEntity, value) => {
    const newGridValues = {
      ...gridValues,
      [colEntity.id]: {
        ...gridValues[colEntity.id],
        [rowEntity.id]: round(Number(value), 1).toString(),
      },
    };
    newGridValues[colEntity.id].total = getTotalForArray(newGridValues[colEntity.id]);
    return newGridValues;
  };

  const onBlurHandler = (colEntity, rowEntity) => {
    const value = gridValues[colEntity.id] ? gridValues[colEntity.id][rowEntity.id] || 0 : 0;

    const newGridValues = createUpdatedGridObject(colEntity, rowEntity, value);
    setGridValues(newGridValues);
    resetCopiedValues();
  };

  const getCopiedColumnId = id => {
    return entityForActivityAllocation ? entityForActivityAllocation.id + id : id;
  };

  const onInputChangeHandler = (cellValue, colEntity, rowEntity, inputBoxId) => {
    if (Number.isNaN(Number(cellValue))) return;
    const value = cellValue || '0';
    if (Number(value) < 0) return;
    const inputElement = document.getElementById(inputBoxId);
    if (inputElement) {
      inputElement.style.borderColor = Number(value) > 100 ? 'red' : '#aab7b8';
    }
    const copiedColumnId = getCopiedColumnId(colEntity.id);
    if (copiedColumn === copiedColumnId) {
      resetCopiedValues();
    }

    const newGridValues = createUpdatedGridObject(colEntity, rowEntity, value);
    setGridValues(newGridValues);
  };

  const handleCopy = colId => {
    const newCopiedValues = {};
    rowsEntity.forEach(row => {
      if (row.id !== 'total') {
        newCopiedValues[row.id] = gridValues[colId] ? gridValues[colId][row.id] || '' : '';
      }
    });
    setCopiedValues(newCopiedValues, getCopiedColumnId(colId));
  };

  const handlePaste = colId => {
    if (!copiedValues || Object.keys(copiedValues).length === 0) return;

    const newGridValues = { ...gridValues };
    newGridValues[colId] = {};
    Object.keys(copiedValues).forEach(rowId => {
      if (copiedValues[rowId] !== '') {
        newGridValues[colId][rowId] = round(Number(copiedValues[rowId] || 0), 1).toString();
      }
    });
    newGridValues[colId].total = getTotalForArray(newGridValues[colId]);
    setGridValues(newGridValues);
  };

  const renderCell = (colIndex, rowEntity) => {
    if (!colsEntity[colIndex]) return null;

    if (rowEntity.id === 'total') {
      const total = gridValues[colsEntity[colIndex].id]
        ? parseFloat(gridValues[colsEntity[colIndex].id].total)
        : 0;
      return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {total.toFixed(2)}%
          {total !== 100 && <Icon name="status-warning" size="small" variant="error" />}
        </div>
      );
    }

    const cellValue =
      (colsEntity[colIndex] &&
        gridValues[colsEntity[colIndex].id] &&
        gridValues[colsEntity[colIndex].id][rowEntity.id]) ||
      '';

    const inputBoxId =
      allocationType === 'Activity'
        ? `${entityForActivityAllocation?.id}#${colsEntity[colIndex].id}#${rowEntity.id}`
        : `${colsEntity[colIndex].id}#${rowEntity.id}`;

    return (
      <div className="percent-input-box-area">
        <SpaceBetween direction="horizontal" size="xs">
          <div className="percent-input-box">
            <Input
              key={inputBoxId}
              type="text"
              value={cellValue}
              onChange={({ detail }) =>
                onInputChangeHandler(detail.value, colsEntity[colIndex], rowEntity, inputBoxId)
              }
              disabled={isReadOnly(surveyDetails)}
              onBlur={() => onBlurHandler(colsEntity[colIndex], rowEntity)}
            />
          </div>
          <div className="percent-sign">%</div>
        </SpaceBetween>
      </div>
    );
  };

  const getEntityDetailsKeyComponent = entityDetail => {
    const [lastName, firstName] = entityDetail.name.split(',');
    switch (surveyType) {
      case SURVEY_TYPE.LABOR:
      default:
        return (
          <div>
            <div>{firstName}</div>
            <div>{lastName}</div>
            <div>{entityDetail.jobTitle}</div>
          </div>
        );
    }
  };

  const renderCopyPasteCell = colId => (
    <div className="copy-paste-cell">
      <SpaceBetween direction="horizontal" size="xs">
        <Button
          variant="inline-link"
          onClick={() => handleCopy(colId)}
          disabled={isReadOnly(surveyDetails) || copiedColumn === getCopiedColumnId(colId)}
        >
          {copiedColumn === getCopiedColumnId(colId) ? 'Copied' : 'Copy'}
        </Button>
        <Button
          variant="inline-link"
          onClick={() => handlePaste(colId)}
          disabled={
            isReadOnly(surveyDetails) || !copiedColumn || copiedColumn === getCopiedColumnId(colId)
          }
        >
          Paste
        </Button>
      </SpaceBetween>
    </div>
  );

  const columns = [
    {
      id: 'rowEntity',
      header: allocationType === 'Activity' ? 'Activities' : 'Projects',
      cell: rowEntity => rowEntity.name,
      sticky: 'left',
      minWidth: 174,
      maxWidth: 174,
    },
    ...colsEntity.map(entity => ({
      id: allocationType === 'Activity' ? entity.id : getEntityDetailsKey(surveyType, entity),
      header: allocationType === 'Activity' ? entity.name : getEntityDetailsKeyComponent(entity),
      cell: rowEntity =>
        rowEntity.id === 'copyPaste'
          ? renderCopyPasteCell(entity.id)
          : renderCell(colsEntity.indexOf(entity), rowEntity),
      minWidth: 144,
    })),
  ];

  const items = [
    {
      id: 'copyPaste',
      name: 'Copy / Paste',
    },
    ...rowsEntity.map(rowEntity => ({
      id: rowEntity.id,
      name: <strong>{rowEntity.name}</strong>,
      description: rowEntity.description,
    })),
    {
      id: 'total',
      name: 'Allocated',
    },
  ];

  return (
    <Table
      columnDefinitions={columns}
      items={items}
      variant="borderless"
      stickyHeader
      stickyColumns={{ first: 1, last: 0 }}
      trackBy="id"
      wrapLines
    />
  );
};

AllocationGrid.propTypes = {
  rowsEntity: PropTypes.array.isRequired,
  colsEntity: PropTypes.array.isRequired,
  allocationType: PropTypes.string.isRequired,
  gridValues: PropTypes.object.isRequired,
  setGridValues: PropTypes.func.isRequired,
  surveyType: PropTypes.string.isRequired,
  surveyDetails: PropTypes.object.isRequired,
  entityForActivityAllocation: PropTypes.object,
  copiedValues: PropTypes.object.isRequired,
  copiedColumn: PropTypes.string.isRequired,
  setCopiedValues: PropTypes.func.isRequired,
};

export default AllocationGrid;
