const initialStateForReducer = {
  projectCopiedValues: {},
  projectCopiedColumn: undefined,
  activityCopiedValues: {},
  activityCopiedColumn: undefined,
};

const copyGridValuesReducer = (state = initialStateForReducer, action) => {
  switch (action.type) {
    case 'SET_COPY_GRID_VALUES':
      return { ...state, ...action.payload.data };
    default:
      return state;
  }
};

export default copyGridValuesReducer;
