import {
  createAPILifecycleActions,
  createAPILifecycleActionTypes,
} from '../../../../../redux/actions/action_utils';
import createAmplifyAPICallEpic from '../../../../../redux/epics/utils';
import { SEARCH_ASSIGNED_EMPLOYEES_ENDPOINT } from '../../../../../common/config/api_endpoints';
import { getSerializedData } from '../../../utils/redux_payload_utils';

const SEARCH_ASSIGNED_EMPLOYEES_TYPES = createAPILifecycleActionTypes(
  'SEARCH_ASSIGNED_EMPLOYEES_TYPES',
);
export const searchAssignedEmployeesAction = createAPILifecycleActions(
  SEARCH_ASSIGNED_EMPLOYEES_TYPES.NAME,
);

const initialState = {
  isFetching: false,
  data: null,
  error: null,
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SEARCH_ASSIGNED_EMPLOYEES_TYPES.BEGIN:
      return {
        ...state,
        isFetching: true,
      };
    case SEARCH_ASSIGNED_EMPLOYEES_TYPES.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: getSerializedData(payload.data),
        error: null,
      };
    case SEARCH_ASSIGNED_EMPLOYEES_TYPES.FAILURE:
      return {
        ...state,
        isFetching: false,
        data: null,
        error: payload,
      };

    case SEARCH_ASSIGNED_EMPLOYEES_TYPES.CLEAR:
      return initialState;
    default:
      return state;
  }
};

export const searchAssignedEmployeesEpic = createAmplifyAPICallEpic({
  actionType: SEARCH_ASSIGNED_EMPLOYEES_TYPES.BEGIN,
  api: SEARCH_ASSIGNED_EMPLOYEES_ENDPOINT,
  successCallback: response => [searchAssignedEmployeesAction.SUCCESS(response.body)],
  errorCallback: () => [searchAssignedEmployeesAction.FAILURE()],
});
