import { get, set } from 'lodash';
import { Auth } from 'aws-amplify';
import {
  createAPILifecycleActions,
  createAPILifecycleActionTypes,
} from '../../../redux/actions/action_utils';
import createAmplifyAPICallEpic from '../../../redux/epics/utils';
import { SAVE_SURVEY_ENDPOINT } from '../../../common/config/api_endpoints';
import { getSurveyDetailsAction } from '../../landing/common/redux/GetSurveyDetails';

export const SAVE_SURVEY_ACTION_TYPES = createAPILifecycleActionTypes('SAVE_SURVEY_ACTION_TYPES');
export const saveSurveyActions = createAPILifecycleActions(SAVE_SURVEY_ACTION_TYPES.NAME);

const initialState = {
  isFetching: false,
  data: null,
  error: null,
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SAVE_SURVEY_ACTION_TYPES.BEGIN:
      return {
        ...state,
        isFetching: true,
        data: null,
        triggeredByAutosave: payload.triggeredByAutosave,
      };
    case SAVE_SURVEY_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: payload,
        error: null,
      };
    case SAVE_SURVEY_ACTION_TYPES.FAILURE:
      return {
        ...state,
        isFetching: false,
        data: null,
        error: payload,
      };

    case SAVE_SURVEY_ACTION_TYPES.CLEAR:
      return initialState;
    default:
      return state;
  }
};

const successCallback = (response, store, action, originalPayload) => {
  const { onSuccess, onFinally } = originalPayload;

  const actionsOut = [saveSurveyActions.SUCCESS(response)];
  if (response && response.body && response.status === 200) {
    if (onSuccess) {
      onSuccess();
    }
    const { surveyId } = response.body;

    actionsOut.push(getSurveyDetailsAction.BEGIN({ body: { surveyId } }));
  }

  if (onFinally) {
    onFinally();
  }

  return actionsOut;
};

const payloadGenerator = async ({ originalPayload }) => {
  let newPayload = originalPayload;
  if (!newPayload) {
    newPayload = {};
  }
  const requestAttributes = get((await Auth.currentSession()).getIdToken(), 'payload', {});
  set(newPayload, ['body', 'lastUpdatedBy'], get(requestAttributes, ['identities', 0, 'userId']));
  return newPayload;
};

export const saveSurveyEpic = createAmplifyAPICallEpic({
  actionType: SAVE_SURVEY_ACTION_TYPES.BEGIN,
  api: SAVE_SURVEY_ENDPOINT,
  payloadGenerator,
  successCallback,
  errorCallback: () => [saveSurveyActions.FAILURE()],
});
