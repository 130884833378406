import { combineReducers } from 'redux';
import assignedEmployees from '../../../app/survey_page/components/Employee/redux/Employee';
import population from '../../../app/survey_page/redux/population';
import surveys from '../../../app/landing/common/redux/GetSurveys';
import surveyDetails from '../../../app/landing/common/redux/GetSurveyDetails';
import reassignSurvey from '../../../app/survey_page/components/Employee/redux/reassignSurvey';
import saveSurvey from '../../../app/survey_page/redux/saveSurvey';
import secondaryAssigneesMap from '../../../app/survey_page/redux/secondaryAssigneesMap';
import updateSurveyLock from '../../../app/landing/common/redux/UpdateSurveyLock';

export default combineReducers({
  assignedEmployees,
  surveys,
  surveyDetails,
  population,
  reassignSurvey,
  saveSurvey,
  secondaryAssigneesMap,
  updateSurveyLock,
});
