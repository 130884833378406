import React from 'react';
import { get, isNull } from 'lodash';
import PropTypes from 'prop-types';
import { AllocationMatrix } from '../../../common/components/AllocationMatrix';
import getProjectMapping from '../../../../utils/ProjectUtils';

export function EmployeeProjectAllocationMatrix({ data }) {
  const projectAllocationData = get(data, 'userResponse.ProjectAllocation.data', {});
  const { searchProjectsResponse } = data;
  const selectedProjects = get(data, 'userResponse.Project.data.selectedProjects', []);
  const projectMapping = getProjectMapping(selectedProjects, searchProjectsResponse);
  const employeeMapping = {};
  const employeeData = get(data, 'employeeData', []);
  if (!isNull(employeeData)) {
    employeeData.forEach(employee => {
      const employeeId = get(employee, 'recordId', '');
      employeeMapping[employeeId] = get(employee, 'formattedEmployeeName', '');
    });
  }
  return (
    <AllocationMatrix
      data={projectAllocationData}
      projectActivityNameMapping={projectMapping}
      entityNameMapping={employeeMapping}
    />
  );
}

EmployeeProjectAllocationMatrix.propTypes = {
  data: PropTypes.object.isRequired,
};

export default EmployeeProjectAllocationMatrix;
