import { Alert } from '@amzn/awsui-components-react';
import PropTypes from 'prop-types';
import React from 'react';
import PDFGenerator from '../pdf_generator/PDFGenerator';

const SurveySubmittedAlert = props => {
  const { surveyDetails, submittedSurveyId } = props;
  return surveyDetails[submittedSurveyId] ? (
    <Alert
      visible="true"
      dismissAriaLabel="Close alert"
      type="success"
      action={<PDFGenerator record={surveyDetails[submittedSurveyId]} />}
    >
      Thank you for submitting the survey
    </Alert>
  ) : null;
};

SurveySubmittedAlert.propTypes = {
  surveyDetails: PropTypes.object,
  submittedSurveyId: PropTypes.string,
};

SurveySubmittedAlert.defaultProps = {
  surveyDetails: {},
  submittedSurveyId: '',
};

export default SurveySubmittedAlert;
