import { combineReducers } from 'redux';
import { createAction } from 'redux-actions';
import { cloneDeep, get, filter, isNull } from 'lodash';
import {
  createAPILifecycleActions,
  createAPILifecycleActionTypes,
} from '../../../../redux/actions/action_utils';
import createAmplifyAPICallEpic from '../../../../redux/epics/utils';
import { LIST_PERMISSIONS_ENDPOINT } from '../../../config/api_endpoints';
import currentDashboard from '../../../../app/landing/common/redux/CurrentDashboard';
import { REASSIGN_SURVEY_TYPES } from '../../../../app/survey_page/components/Employee/redux/reassignSurvey';
import { notificationActionHandler } from '../notifications/utils';
import { SAVE_SURVEY_ACTION_TYPES } from '../../../../app/survey_page/redux/saveSurvey';
import { UPDATE_SURVEY_LOCK_ACTION_TYPES } from '../../../../app/landing/common/redux/UpdateSurveyLock';

export const SET_PAGE_NOTIFICATION = 'app/common/ui/set_page_notification';
export const setPageNotification = createAction(SET_PAGE_NOTIFICATION);
export const CLEAR_PAGE_NOTIFICATION = 'app/common/ui/clear_page_notification';
export const clearPageNotification = createAction(CLEAR_PAGE_NOTIFICATION);

export const SET_PAGE_LOADING = 'app/common/ui/set_page_loading';
export const setPageLoading = createAction(SET_PAGE_LOADING);

const LIST_PERMISSIONS_ACTION_TYPES = createAPILifecycleActionTypes(
  'LIST_PERMISSIONS_ACTION_TYPES',
);

const loadingInitialState = false;
const loading = (state = loadingInitialState, action) => {
  switch (action.type) {
    case SET_PAGE_LOADING:
    case LIST_PERMISSIONS_ACTION_TYPES.BEGIN:
    case REASSIGN_SURVEY_TYPES.BEGIN:
    case UPDATE_SURVEY_LOCK_ACTION_TYPES.BEGIN:
      return true;
    case REASSIGN_SURVEY_TYPES.SUCCESS:
    case REASSIGN_SURVEY_TYPES.FAILURE:
    case LIST_PERMISSIONS_ACTION_TYPES.SUCCESS:
    case LIST_PERMISSIONS_ACTION_TYPES.FAILURE:
    case SAVE_SURVEY_ACTION_TYPES.SUCCESS:
    case SAVE_SURVEY_ACTION_TYPES.FAILURE:
    case UPDATE_SURVEY_LOCK_ACTION_TYPES.SUCCESS:
    case UPDATE_SURVEY_LOCK_ACTION_TYPES.FAILURE:
      return false;
    default:
      return state;
  }
};

const notificationInitialState = [];
const notification = (state = notificationInitialState, action) => {
  switch (action.type) {
    case REASSIGN_SURVEY_TYPES.SUCCESS:
      return [...state, notificationActionHandler(action)];
    case SAVE_SURVEY_ACTION_TYPES.SUCCESS: {
      if (!isNull(notificationActionHandler(action)))
        return [...state, notificationActionHandler(action)];
      return state;
    }
    case UPDATE_SURVEY_LOCK_ACTION_TYPES.SUCCESS:
      if (!isNull(notificationActionHandler(action)))
        return [...state, notificationActionHandler(action)];
      return state;
    case SET_PAGE_NOTIFICATION:
      return [...state, action.payload];
    case CLEAR_PAGE_NOTIFICATION: {
      return filter(cloneDeep(state), ({ id }) => id !== get(action.payload, 'id'));
    }
    default:
      return state;
  }
};

export const listPermissionsAction = createAPILifecycleActions(LIST_PERMISSIONS_ACTION_TYPES.NAME);
const permissionsInitialState = {
  isFetching: false,
  data: null,
  error: null,
};
const permissions = (state = permissionsInitialState, { type, payload }) => {
  switch (type) {
    case LIST_PERMISSIONS_ACTION_TYPES.BEGIN:
      return {
        ...state,
        isFetching: true,
      };
    case LIST_PERMISSIONS_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: payload.permissions,
        error: null,
      };
    case LIST_PERMISSIONS_ACTION_TYPES.FAILURE:
      return {
        ...state,
        isFetching: false,
        data: null,
        error: payload,
      };

    case LIST_PERMISSIONS_ACTION_TYPES.CLEAR:
      return permissionsInitialState;
    default:
      return state;
  }
};

export const listPermissionsEpic = createAmplifyAPICallEpic({
  actionType: LIST_PERMISSIONS_ACTION_TYPES.BEGIN,
  api: LIST_PERMISSIONS_ENDPOINT,
  successCallback: response => [listPermissionsAction.SUCCESS(response.body)],
  errorCallback: () => [listPermissionsAction.FAILURE()],
});

export default combineReducers({
  ui: combineReducers({
    loading,
    notification,
    currentDashboard,
  }),
  permissions,
});
