import { isUndefined, isNull, isEmpty } from 'lodash';

export const convertISOToDateTime = str => {
  return new Date(str);
};

/**
 * Function to convert date to particular timeZone date
 */
export const getDateInYYYYMMDDForTimezone = (str, timezone) => {
  if (isNull(str) || isUndefined(str)) return null;
  return new Date(str).toLocaleTimeString('en-CA', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone: timezone,
  });
};

export const getDate = str => {
  if (isNull(str) || isUndefined(str)) return null;
  const dateInUS = getDateInYYYYMMDDForTimezone(str, 'America/Los_Angeles');
  return dateInUS.toString().split(',')[0];
};

export const getDateTime = str => {
  return isNull(str) || isUndefined(str)
    ? null
    : convertISOToDateTime(str).toLocaleString('en-US', { timeZone: 'America/Los_Angeles' });
};

export const getBusinessDate = str => {
  if (isEmpty(str)) return null;
  const date = getDate(str);
  const time = ', 23:59:59 PM PST';
  return date + time;
};
