import { Box, Button, Container, Grid, Header, SpaceBetween } from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { forEach, get, isEmpty, map, size, join, max } from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { DEFAULT_BUSINESS_ID, ITEM_TYPE_LABOR } from '../../../../common/config/api_constants';
import { SEARCH_POPULATION_ENDPOINT, VALARI_API } from '../../../../common/config/api_endpoints';
import SecondaryAssigneesCards from '../SecondaryAssigneesCards/SecondaryAssigneesCards';
import { getSerializedData } from '../../utils/redux_payload_utils';
import SearchPopulationDropdown from '../SearchPopulationDropdown/SearchPopulationDropdown';
import getDocById from '../../../../common/components/doc_link/utils/getDocById';
import FAQ from '../../../../common/constants/documentIds';
import DocLink from '../../../../common/components/doc_link/utils/DocLink';
import { saveSurveyActions } from '../../redux/saveSurvey';
import { updateSecondaryAssigneesAction } from '../../redux/secondaryAssigneesMap';
import addContextToPayload from '../../../../common/utils/api_util';
import { getStudyPeriod } from '../../../../common/constants/study_period';
import { isReadOnly } from '../../utils/survey_page_utils';

const SecondaryAssignee = ({
  state,
  saveSurvey,
  secondaryAssigneesMap,
  surveyContext,
  surveyId,
  updateSecondaryAssignees,
  userResponse,
  studyPeriod,
  updateSurveyLockData,
  derivedClientId,
}) => {
  const initialState = {
    selectedSME: null,
    filterText: '',
    SMEs: [],
  };
  const surveyDetails = get(surveyContext, [surveyId], {});
  const { userAlias } = surveyDetails;

  const getInitialSMEs = () => {
    return `recordId equals ${join(
      secondaryAssigneesMap[surveyDetails.surveyId],
      ' || recordId equals ',
    )}`;
  };

  const getMappedSMEs = list => {
    return map(
      list,
      ({
        formattedEmployeeName,
        employeeAlias,
        jobTitle,
        recordId,
        phoneToolURL,
        badgePhotoURL,
      }) => {
        return {
          label: formattedEmployeeName,
          description: employeeAlias,
          tags: [jobTitle],
          recordId,
          phoneToolURL,
          badgePhotoURL,
        };
      },
    );
  };

  const [selectedSMEState, setSelectedSMEState] = useState(initialState);
  useEffect(() => {
    if (size(secondaryAssigneesMap[surveyDetails.surveyId]) > 0) {
      addContextToPayload(VALARI_API, SEARCH_POPULATION_ENDPOINT, studyPeriod, {
        body: {
          clientId: derivedClientId,
          itemType: ITEM_TYPE_LABOR,
          pageSize: 50,
          pageNumber: 1,
          query: getInitialSMEs(),
        },
      }).then(res => {
        setSelectedSMEState({
          ...selectedSMEState,
          SMEs: getMappedSMEs(getSerializedData(get(res, 'body.data', ''))),
        });
        updateSecondaryAssignees({ [surveyId]: secondaryAssigneesMap[surveyDetails.surveyId] });
      });
    } else {
      setSelectedSMEState({
        ...selectedSMEState,
        SMEs: [],
      });
    }
    // eslint-disable-next-line
  }, [size(secondaryAssigneesMap[surveyDetails.surveyId])]);

  const secondaryHeader = () => {
    return (
      <Header
        counter={`(${size(selectedSMEState.SMEs)})`}
        variant="h2"
        info={<DocLink pdf={getDocById(FAQ)} text="What is secondary assignee?" variant="info" />}
      >
        Secondary assignee - optional
      </Header>
    );
  };

  const saveSurveyCall = newSecondaryAssignees => {
    const activeStepContext = get(JSON.parse(userResponse), 'activeStep', 0);
    saveSurvey({
      body: {
        clientId: derivedClientId || DEFAULT_BUSINESS_ID,
        surveyId: surveyDetails.surveyId,
        userResponse: JSON.stringify({
          ...state,
          activeStep: max([state.activeStep, activeStepContext]),
        }),
        secondaryAssignees: map(newSecondaryAssignees, ({ recordId }) => recordId),
        version: surveyDetails.lastUpdatedOn,
        sessionId: updateSurveyLockData.sessionId,
      },
    });
  };

  const addTokenHandler = () => {
    const newSecondaryAssignees = [...selectedSMEState.SMEs, selectedSMEState.selectedSME];
    saveSurveyCall(newSecondaryAssignees);
    setSelectedSMEState({
      ...selectedSMEState,
      filterText: '',
      selectedSME: null,
    });
  };
  const isAddDisabled = () => {
    let alreadyPresent = false;
    if (
      isEmpty(selectedSMEState.selectedSME) ||
      get(selectedSMEState, 'selectedSME.description') === userAlias
    )
      alreadyPresent = true;
    forEach(selectedSMEState.SMEs, sme => {
      if (sme.recordId === get(selectedSMEState, 'selectedSME.recordId', '')) {
        alreadyPresent = true;
      }
    });
    return alreadyPresent || isReadOnly(surveyDetails);
  };

  const onCancelHandler = index => {
    const newSecondaryAssignees = [
      ...selectedSMEState.SMEs.slice(0, index),
      ...selectedSMEState.SMEs.slice(index + 1),
    ];
    saveSurveyCall(newSecondaryAssignees);
  };

  return (
    <Container header={secondaryHeader()}>
      <SpaceBetween size="m">
        <Box color="text-body-secondary">
          Add secondary assignee(s) to complete the survey with you.
        </Box>
        <Grid gridDefinition={[{ colspan: 9 }, { colspan: 3 }]}>
          <SearchPopulationDropdown
            state={selectedSMEState}
            setState={setSelectedSMEState}
            isReadOnly={isReadOnly(surveyDetails)}
            placeholder="Enter employee alias"
          />
          <Button onClick={addTokenHandler} disabled={isAddDisabled()}>
            Add
          </Button>
        </Grid>
        <SecondaryAssigneesCards
          list={selectedSMEState.SMEs}
          onCancel={onCancelHandler}
          isReadOnly={isReadOnly(surveyDetails)}
        />
      </SpaceBetween>
    </Container>
  );
};

SecondaryAssignee.propTypes = {
  state: PropTypes.object.isRequired,
  saveSurvey: PropTypes.func.isRequired,
  secondaryAssigneesMap: PropTypes.object.isRequired,
  surveyContext: PropTypes.object.isRequired,
  surveyId: PropTypes.string.isRequired,
  updateSecondaryAssignees: PropTypes.func.isRequired,
  userResponse: PropTypes.string.isRequired,
  studyPeriod: PropTypes.string.isRequired,
  updateSurveyLockData: PropTypes.object.isRequired,
  derivedClientId: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  surveyId: get(state, 'router.location.state.headerDetails.surveyId'),
  surveyContext: get(state, 'entities.surveyDetails.data'),
  secondaryAssigneesMap: get(state, 'entities.secondaryAssigneesMap'),
  studyPeriod: getStudyPeriod(state),
  updateSurveyLockData: get(state, 'entities.updateSurveyLock.data.body', {}),
  derivedClientId: get(state, 'derivedClientId.derivedClientId', undefined),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      saveSurvey: saveSurveyActions.BEGIN,
      updateSecondaryAssignees: updateSecondaryAssigneesAction,
    },
    dispatch,
  );

export default connect(mapStateToProps, mapDispatchToProps)(SecondaryAssignee);
