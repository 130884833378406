import { get, isEmpty, isNull } from 'lodash';

const getProjectMapping = (selectedProjects, searchProjectsResponse) => {
  const projectMapping = {};
  if (!isNull(selectedProjects) && !isNull(searchProjectsResponse)) {
    selectedProjects.forEach(selectedProject => {
      const projectId = get(selectedProject, 'id', '');
      const searchedProject = get(searchProjectsResponse, [projectId], {});
      projectMapping[projectId] = isEmpty(searchedProject)
        ? get(selectedProject, 'name', '')
        : get(searchedProject, 'projectName', '');
    });
  }
  return projectMapping;
};

export default getProjectMapping;
