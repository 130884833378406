export const documentTypes = [
  { label: 'Architecture Design Documents', value: 'Architecture Design Documents' },
  { label: 'Business Requirements Document (BRD)', value: 'Business Requirements Document (BRD)' },
  { label: 'Certification Test Reports', value: 'Certification Test Reports' },
  {
    label: 'Daily Log (of functional verification runs)',
    value: 'Daily Log (of functional verification runs)',
  },
  { label: 'Lessons Learned Meeting Documents', value: 'Lessons Learned Meeting Documents' },
  { label: 'Monthly Progress Reports', value: 'Monthly Progress Reports' },
  {
    label:
      'Other documentation that supports qualification of the development work for research credit',
    value:
      'Other documentation that supports qualification of the development work for research credit',
  },
  {
    label: 'Press Release Frequently Asked Questions document (PRFAQ)',
    value: 'Press Release Frequently Asked Questions document (PRFAQ)',
  },
  { label: 'Project Timelines', value: 'Project Timelines' },
  { label: 'Quarterly Business Reviews (QBR)', value: 'Quarterly Business Reviews (QBR)' },
  { label: 'Simulation Runs (Computer)', value: 'Simulation Runs (Computer)' },
  { label: 'Sprint documentation', value: 'Sprint documentation' },
  {
    label: 'Technical Design Specifications/Drawings',
    value: 'Technical Design Specifications/Drawings',
  },
  { label: 'Verification of Specifications Tests', value: 'Verification of Specifications Tests' },
  { label: 'Wiki, Confluence and SIM pages', value: 'Wiki, Confluence and SIM pages' },
];

export const projectDocumentTableSchema = [
  {
    key: 'project',
    displayName: 'Project',
  },
  {
    key: 'type',
    displayName: 'Type',
  },
  {
    key: 'document',
    displayName: 'Document / Links',
  },
  {
    key: 'actions',
    displayName: 'Actions',
  },
];
export const projectDocumentTableFilterOn = ['project', 'type', 'documentFileName'];
