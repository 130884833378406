import FAQDoc from '../pdfs/RadicalFAQ.pdf';
import FAQ from '../../../constants/documentIds';

const getDocById = docId => {
  switch (docId) {
    case FAQ:
      return FAQDoc;
    default:
      return null;
  }
};
export default getDocById;
