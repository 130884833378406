import { createAction } from 'redux-actions';
import { GET_SURVEYS_ENDPOINT } from '../../../../common/config/api_endpoints';
import {
  createAPILifecycleActions,
  createAPILifecycleActionTypes,
} from '../../../../redux/actions/action_utils';
import createAmplifyAPICallEpic from '../../../../redux/epics/utils';
import { getSurveyDetailsAction } from './GetSurveyDetails';

const GET_SURVEYS_ACTION_TYPES = createAPILifecycleActionTypes('GET_SURVEYS_ACTION_TYPES');
export const getSurveysAction = createAPILifecycleActions(GET_SURVEYS_ACTION_TYPES.NAME);

const SET_SURVEYS_ACTION = 'SET_SURVEYS_ACTION';
export const setSurveysActon = createAction(SET_SURVEYS_ACTION);

const initialState = {
  isFetching: false,
  data: null,
  error: null,
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_SURVEYS_ACTION_TYPES.BEGIN:
      return {
        ...state,
        isFetching: true,
      };
    case GET_SURVEYS_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: payload.body,
        error: null,
      };
    case GET_SURVEYS_ACTION_TYPES.FAILURE:
      return {
        ...state,
        isFetching: false,
        data: null,
        error: payload,
      };
    case SET_SURVEYS_ACTION:
      return {
        ...state,
        isFetching: false,
        data: payload,
        error: null,
      };
    case GET_SURVEYS_ACTION_TYPES.CLEAR:
      return initialState;
    default:
      return state;
  }
};

const successCallback = response => {
  const actionsOut = [getSurveysAction.SUCCESS(response)];
  if (response && response.body) {
    const { primaryAssigneeSurveys, secondaryAssigneeSurveys } = response.body;
    primaryAssigneeSurveys.forEach(surveyId => {
      const payload = {
        body: {
          surveyId,
        },
      };
      actionsOut.push(getSurveyDetailsAction.BEGIN(payload));
    });
    secondaryAssigneeSurveys.forEach(surveyId => {
      const payload = {
        body: {
          surveyId,
        },
      };
      actionsOut.push(getSurveyDetailsAction.BEGIN(payload));
    });
  }

  return actionsOut;
};

export const getSurveysEpic = createAmplifyAPICallEpic({
  actionType: GET_SURVEYS_ACTION_TYPES.BEGIN,
  api: GET_SURVEYS_ENDPOINT,
  successCallback,
  errorCallback: () => [getSurveysAction.FAILURE()],
});
