const initialState = {
  derivedClientId: undefined,
};

const derivedClientIdReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_DERIVED_CLIENT_ID':
      return { ...state, derivedClientId: action.payload.derivedClientId };
    default:
      return state;
  }
};

export default derivedClientIdReducer;
