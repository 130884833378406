import { TextFilter } from '@amzn/awsui-components-react';
import * as React from 'react';
import { filter, lowerCase, startsWith, get } from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';

const TextFiltering = ({ setVisibleItems, filterOn, data, filteringPlaceholder }) => {
  const [filteringText, setFilteringText] = React.useState('');
  const [totalMatches, setTotalMatches] = useState(0);
  let count = 0;
  const isFilterAllow = (detail, ob) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const filterColumnName of filterOn) {
      if (startsWith(lowerCase(get(ob, [filterColumnName], '')), lowerCase(detail.filteringText))) {
        count += 1;
        return true;
      }
    }
    return false;
  };

  const handleFiltering = ({ detail }) => {
    setFilteringText(detail.filteringText);
    setVisibleItems(filter(data, ob => isFilterAllow(detail, ob)));
    setTotalMatches(count);
  };

  return (
    <TextFilter
      filteringText={filteringText}
      onChange={handleFiltering}
      filteringPlaceholder={filteringPlaceholder}
      countText={`${totalMatches} match`}
    />
  );
};
TextFiltering.propTypes = {
  setVisibleItems: PropTypes.func.isRequired,
  filterOn: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  filteringPlaceholder: PropTypes.string.isRequired,
};

export default TextFiltering;
