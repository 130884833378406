import { get } from 'lodash';
import { createAction } from 'redux-actions';
import { ROUTE_PATH } from '../../../../common/constants/route_paths';

const SET_CURRENT_DASHBOARD = 'SET_CURRENT_DASHBOARD';
export const setCurrentDashboardAction = createAction(SET_CURRENT_DASHBOARD);

const initialState = {
  routePath: ROUTE_PATH.DASHBOARD,
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CURRENT_DASHBOARD:
      return {
        ...state,
        surveyId: get(payload, 'surveyId', null),
        routePath: get(payload, 'routePath', ROUTE_PATH.DASHBOARD),
      };

    default:
      return state;
  }
};
