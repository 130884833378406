import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  ExpandableSection,
  Header,
  Link,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import PropTypes from 'prop-types';
import { get, size } from 'lodash';
import { connect } from 'react-redux';
import ReassignModal from '../../Employee/components/ReassignModal';
import SecondaryAssignee from '../../SecondaryAssignee/SecondaryAssignee';
import DocLink from '../../../../../common/components/doc_link/utils/DocLink';
import getDocById from '../../../../../common/components/doc_link/utils/getDocById';
import FAQ from '../../../../../common/constants/documentIds';
import VendorTable from './VendorTable';
import { headerTitles } from '../constants/reassign_survey_constants';
import { isReadOnly } from '../../../utils/survey_page_utils';

const Vendor = ({
  state,
  pageContents,
  setPageElements,
  surveyContext,
  surveyId,
  surveyType,
  surveyDetails,
}) => {
  const year = get(surveyContext, [surveyId, 'year'], '');
  const userResponse = get(surveyContext, [surveyId, 'userResponse'], '{}');
  const [isReassignModalVisible, setIsReassignModalVisible] = useState(false);
  const vendorList = get(pageContents, 'Vendor.vendorList', []);
  const showReassignModal = () => {
    setIsReassignModalVisible(true);
  };
  const hideReassignModal = () => {
    setIsReassignModalVisible(false);
  };

  const primaryHeader = () => {
    return (
      <Header
        counter={`(${size(vendorList)})`}
        variant="h2"
        info={
          <DocLink pdf={getDocById(FAQ)} text="Why am I seeing these supplier(s)?" variant="info" />
        }
      >
        Supplier
      </Header>
    );
  };

  return (
    <SpaceBetween size="l">
      <Container header={primaryHeader()}>
        <SpaceBetween size="m">
          <Box color="text-body-secondary">
            Review the supplier(s) assigned to your survey. You will be asked to enter the expenses
            each supplier spent on various projects and activities in {year} for each supplier.
          </Box>
          <VendorTable vendorList={vendorList} headerTitles={headerTitles} />
          <SpaceBetween size="xxxs">
            <Box color="text-body-secondary">
              Need to add or remove an expense from your survey?
            </Box>
            <Box color="text-body-secondary">
              Contact <Link href="mailto:ukrdtax@amazon.com">ukrdtax@amazon.com</Link>
            </Box>
          </SpaceBetween>
        </SpaceBetween>
      </Container>
      <SecondaryAssignee
        state={state}
        pageContents={pageContents}
        setPageContents={setPageElements}
        userResponse={userResponse}
        surveyType={surveyType}
      />
      <ExpandableSection
        variant="container"
        header={<Header variant="h2">Not the right person to complete the survey?</Header>}
      >
        <SpaceBetween size="m">
          <Box color="text-body-secondary" variant="p">
            Reassign the survey to another person who you think can provide details on the
            supplier(s)&apos; time spent on {year} projects and activities. It is recommended you
            confirm with the person you&apos;re reassigning to. Once the survey is reassigned, you
            no longer have access to this survey.
          </Box>
          <Button onClick={showReassignModal} disabled={isReadOnly(surveyDetails)}>
            Reassign the survey
          </Button>
        </SpaceBetween>
      </ExpandableSection>
      <ReassignModal
        onCancel={hideReassignModal}
        visibility={isReassignModalVisible}
        onConfirm={hideReassignModal}
        surveyType={surveyType}
      />
    </SpaceBetween>
  );
};

const mapStateToProps = state => {
  return {
    surveyId: get(state, 'router.location.state.headerDetails.surveyId'),
    surveyContext: get(state, 'entities.surveyDetails.data'),
  };
};

Vendor.propTypes = {
  state: PropTypes.object.isRequired,
  pageContents: PropTypes.object.isRequired,
  setPageElements: PropTypes.func.isRequired,
  surveyId: PropTypes.string.isRequired,
  surveyContext: PropTypes.object.isRequired,
  surveyType: PropTypes.string.isRequired,
  surveyDetails: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, null)(Vendor);
