import React from 'react';
import { StyleSheet, Text, View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import { SurveyEmployeeListTable } from './SurveyEmployeeListTable';

const styles = StyleSheet.create({
  section: {
    fontSize: 10,
    paddingLeft: 7,
    color: '#8c8c8c',
  },
  subHeading: {
    paddingTop: 16,
    paddingBottom: 6,
    paddingLeft: 5,
    color: '#0187b7',
    fontSize: 11,
    fontStyle: 'bold',
    fontFamily: 'Helvetica-Bold',
  },
});

export function SurveySecondaryAssigneeSection({ data }) {
  const { section, subHeading } = styles;

  const secondaryAssigneeDataList = get(data, 'secondaryAssigneeDetails', []);
  if (!isEmpty(secondaryAssigneeDataList)) {
    return (
      <View>
        <Text style={subHeading}>Secondary assignee - optional</Text>
        <Text style={section}>
          Add secondary assignee(s) to complete the survey with you, if you need help providing the
          percentage of time allocation on various projects and activities in {data.studyPeriod} for
          above employees.
        </Text>
        <SurveyEmployeeListTable employeeData={secondaryAssigneeDataList} />
      </View>
    );
  }

  return <View />;
}

SurveySecondaryAssigneeSection.propTypes = {
  data: PropTypes.object.isRequired,
};

export default SurveySecondaryAssigneeSection;
