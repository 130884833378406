import { isUndefined } from 'lodash';
import {
  createMetricsPublisherForMethod,
  createMetricsPublisherForContext,
  createMetricsDriver,
  createTimerStopwatchMetric,
} from './katal_metrics';

let instance;

class MetricsUtility {
  metricsDriver;

  constructor() {
    if (instance) {
      throw new Error('New instance cannot be created.');
    }
    instance = this;
    this.metricsDriver = createMetricsDriver();
  }

  pagePublisherMap = new Map();

  getPagePublisher(pageName) {
    const pagePublisher = this.pagePublisherMap.get(pageName);
    if (isUndefined(pagePublisher)) {
      throw new Error('Page context not available for given pageName.');
    }
    return pagePublisher;
  }

  methodPublisherMap = new Map();

  getMethodPublisher(methodName) {
    const methodPublisher = this.methodPublisherMap.get(methodName);
    if (isUndefined(methodPublisher)) {
      throw new Error('Method publisher not available for given methodName.');
    }
    return methodPublisher;
  }

  timerMetricMap = new Map();

  getTimerMetric(timerName) {
    const timerMetric = this.timerMetricMap.get(timerName);
    return timerMetric;
  }

  // MARK: - Register Functions
  registerPage(pageName, relatedMetrics) {
    this.pagePublisherMap.set(
      pageName,
      createMetricsPublisherForContext(this.metricsDriver, pageName, relatedMetrics),
    );
  }

  registerMethod(pageName, methodName) {
    this.methodPublisherMap.set(
      methodName,
      createMetricsPublisherForMethod(this.getPagePublisher(pageName), methodName),
    );
  }

  // MARK: - Publish Functions
  publishStringMetric(methodName, name, value) {
    this.getMethodPublisher(methodName).publishString(name, value);
  }

  publishTimerMetric(methodName, name, value) {
    this.getMethodPublisher(methodName).publishTimerMonitor(name, value);
  }

  publishCounterMetric(methodName, name, value) {
    this.getMethodPublisher(methodName).publishCounterMonitor(name, value);
  }

  // MARK: - Timer Functions
  startTimer(timerName, name) {
    this.timerMetricMap.set(timerName, createTimerStopwatchMetric(name));
  }

  invalidateTimerAndPublish(methodName, timerName) {
    const timerMetric = this.getTimerMetric(timerName);
    if (isUndefined(timerMetric)) {
      return;
    }
    this.timerMetricMap.delete(timerName);
    this.getMethodPublisher(methodName).publish(timerMetric);
  }
}

const metricsUtility = Object.freeze(new MetricsUtility());

export default metricsUtility;
