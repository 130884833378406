import { map } from 'lodash';

const getColumnDefinitions = schema => {
  return map(schema, ({ key: id, displayName: header }) => {
    const cell = e => e[id];
    return { id, header, cell };
  });
};

export default getColumnDefinitions;
