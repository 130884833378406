import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, SpaceBetween, Icon, TextContent, Box } from '@amzn/awsui-components-react';

const FillMatrixWithExcel = ({ onDownloadTemplate, onFillMatrix, isVisible, setModalVisible }) => {
  const [file, setFile] = useState(null);
  const [isUploadEnabled, setIsUploadEnabled] = useState(false);

  const handleFileChange = event => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setIsUploadEnabled(true);
    }
  };

  const handleUploadClick = () => {
    if (file && onFillMatrix) {
      onFillMatrix(file);
      setIsUploadEnabled(false);
      setModalVisible(false);
    }
  };

  const handleDismiss = () => {
    setIsUploadEnabled(false);
    setModalVisible(false);
  };

  return (
    <Modal
      visible={isVisible}
      onDismiss={handleDismiss}
      size="medium"
      header="Fill matrix with Excel"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button onClick={handleDismiss}>Cancel</Button>
            <Button disabled={!isUploadEnabled} onClick={handleUploadClick} variant="primary">
              Upload
            </Button>
          </SpaceBetween>
        </Box>
      }
    >
      <div>
        <TextContent>
          <p>
            <strong>Step 1: Download Excel template sheet</strong>
          </p>
          <Button onClick={onDownloadTemplate}>
            <Icon name="download" /> Download template
          </Button>

          <p>
            <strong>Step 2: Fill in Excel sheet</strong>
          </p>
          <p>
            Open the Excel sheet you just downloaded and fill it in with information based on the
            knowledge you have. You can share the Excel sheet with others and let them help you fill
            it in.
          </p>

          <p>
            <strong>Step 3: Upload completed Excel sheet</strong>
          </p>
          <Button onClick={() => document.getElementById('file-upload').click()}>
            <Icon name="upload" /> Upload Excel sheet
          </Button>
          {isUploadEnabled && (
            <div>
              <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                <Icon name="status-positive" size="small" variant="success" />
                <p style={{ margin: 0, fontWeight: 500, lineHeight: '1.2' }}>{file.name}</p>
              </div>
              <div style={{ marginTop: '2px', lineHeight: '1.1' }}>
                <p
                  style={{
                    margin: 0,
                    fontWeight: 400,
                    color: '#6b7280',
                    fontSize: '13px',
                    lineHeight: '1.1',
                  }}
                >
                  {(file.size / (1024 * 1024)).toFixed(2)} MB
                </p>
                <p
                  style={{
                    margin: 0,
                    fontWeight: 400,
                    color: '#6b7280',
                    fontSize: '13px',
                    lineHeight: '1.1',
                  }}
                >
                  {new Date(file.lastModified).toLocaleString()}
                </p>
              </div>
            </div>
          )}
        </TextContent>
        <input
          id="file-upload"
          type="file"
          style={{ display: 'none' }}
          onChange={handleFileChange}
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        />
      </div>
    </Modal>
  );
};

FillMatrixWithExcel.propTypes = {
  onDownloadTemplate: PropTypes.func.isRequired,
  onFillMatrix: PropTypes.func.isRequired,
  isVisible: PropTypes.bool.isRequired,
  setModalVisible: PropTypes.func.isRequired,
};

export default FillMatrixWithExcel;
