import { combineEpics } from 'redux-observable';
import { searchAssignedEmployeesEpic } from '../../app/survey_page/components/Employee/redux/Employee';
import { getSurveysEpic } from '../../app/landing/common/redux/GetSurveys';
import { getSurveyDetailsEpic } from '../../app/landing/common/redux/GetSurveyDetails';
import { searchPopulationEpic } from '../../app/survey_page/redux/population';
import { listPermissionsEpic } from '../../common/components/with_page/redux/reducer';
import { reassignSurveyEpic } from '../../app/survey_page/components/Employee/redux/reassignSurvey';
import { saveSurveyEpic } from '../../app/survey_page/redux/saveSurvey';
import { updateSurveyLockEpic } from '../../app/landing/common/redux/UpdateSurveyLock';

export default combineEpics(
  listPermissionsEpic,
  searchAssignedEmployeesEpic,
  getSurveysEpic,
  getSurveyDetailsEpic,
  searchPopulationEpic,
  reassignSurveyEpic,
  saveSurveyEpic,
  updateSurveyLockEpic,
);
