import * as React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import moment from 'moment';

const SaveInformation = ({ surveyId, surveyContext, isSavingSurvey, isFetchingSurveyDetails }) => {
  const [saveInformationText, setSaveInformationText] = React.useState('All changes saved');
  const surveyDetails = get(surveyContext, ['data', surveyId], {});
  const { lastUpdatedOn } = surveyDetails;
  const isSaveInProgress = isSavingSurvey || isFetchingSurveyDetails;

  const getTimeDiffText = lastUpdatedOnTime => {
    const currentTime = new Date().valueOf();
    const lastUpdatedTime = new Date(lastUpdatedOnTime).valueOf();
    const timeDiff = currentTime - lastUpdatedTime;

    // eslint-disable-next-line new-cap
    const diff = new moment.duration(timeDiff);

    if (diff.asYears() >= 1) {
      return `Saved ${Math.floor(diff.asYears())} year(s) ago.`;
    }
    if (diff.asMonths() >= 1) {
      return `Saved ${Math.floor(diff.asMonths())} month(s) ago.`;
    }
    if (diff.asDays() >= 1) {
      return `Saved ${Math.floor(diff.asDays())} day(s) ago.`;
    }
    if (diff.asHours() >= 1) {
      return `Saved ${Math.floor(diff.asHours())} hour(s) ago.`;
    }
    if (diff.asMinutes() >= 1) {
      return `Saved ${Math.floor(diff.asMinutes())} minute(s) ago.`;
    }
    return 'Saved a few seconds ago';
  };

  // To update save state whenever changes are being saved
  React.useEffect(() => {
    if (isSaveInProgress) {
      setSaveInformationText('Saving changes..');
    }
  }, [isSaveInProgress]);

  // To update save state every 5 seconds
  React.useEffect(() => {
    const timer = setInterval(() => {
      if (!isSaveInProgress) {
        setSaveInformationText(getTimeDiffText(lastUpdatedOn));
      }
    }, 5 * 1000);
    return () => {
      clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastUpdatedOn]);
  return <small>{saveInformationText}</small>;
};

SaveInformation.propTypes = {
  isSavingSurvey: PropTypes.bool.isRequired,
  isFetchingSurveyDetails: PropTypes.bool.isRequired,
  surveyId: PropTypes.string.isRequired,
  surveyContext: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
  return {
    surveyId: get(state, 'router.location.state.headerDetails.surveyId'),
    surveyContext: get(state, 'entities.surveyDetails'),
    isSavingSurvey: get(state, 'entities.saveSurvey.isFetching', false),
    isFetchingSurveyDetails: get(state, 'entities.surveyDetails.isFetching', false),
  };
};

export default connect(mapStateToProps, null)(SaveInformation);
