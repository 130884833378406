import { Link } from '@amzn/awsui-components-react';
import React from 'react';
import PropTypes from 'prop-types';

const DocLink = ({ pdf, text, variant }) => {
  return (
    <Link href={pdf} target="_blank" variant={variant}>
      {text}
    </Link>
  );
};
DocLink.propTypes = {
  pdf: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  variant: PropTypes.string,
};
export default DocLink;
