import { get, isEmpty } from 'lodash';
import { getEntityListPath } from '../../../../common/constants/surveyType';

const validateProjectAllocation = (pageElements, surveyType, projectAllocation) => {
  const entityList = get(pageElements, getEntityListPath(surveyType), []);
  const project = projectAllocation.data;

  if (isEmpty(project) || Object.keys(project).length !== entityList.length) return false;
  let validation = true;
  Object.keys(project).forEach(employee => {
    if (Number(project[employee].total) !== 100) {
      validation = false;
    }
  });
  return validation;
};

export default validateProjectAllocation;
