import { get } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import Introduction from './Introduction';
import SurveyDetail from '../../../../../common/components/survey_details/SurveyDetailsComponent';
import getSurveyDetailsList from '../../utils/utils';

const SecondaryAssigneeSurveys = ({ surveys, surveyDetails }) => {
  const secondarySurveyIds = get(surveys, 'secondaryAssigneeSurveys', []);
  const secondarySurveyDetails = getSurveyDetailsList(secondarySurveyIds, surveyDetails);
  return (
    <React.Fragment>
      <Introduction />
      <SurveyDetail assignees={secondarySurveyDetails} />
    </React.Fragment>
  );
};

SecondaryAssigneeSurveys.propTypes = {
  surveys: PropTypes.object.isRequired,
  surveyDetails: PropTypes.object.isRequired,
};

export default SecondaryAssigneeSurveys;
