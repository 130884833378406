import { Box, ExpandableSection, SpaceBetween } from '@amzn/awsui-components-react';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import ShowReassignmentInfo from './ShowReassignmentInfo';
import PrimaryAssignee from './PrimaryAssignee';
import DisplayEmployeeInformation from './DisplayEmployeeInformation';
import { DEFAULT_BUSINESS_ID } from '../../../config/api_constants';

const AssignmentDetail = ({ assignee, isPrimaryAssignee }) => {
  return (
    <ExpandableSection variant="footer" header="Assignment detail" defaultExpanded>
      <SpaceBetween size="m">
        <ShowReassignmentInfo assignee={assignee} />
        <PrimaryAssignee isPrimaryAssignee={isPrimaryAssignee} assignee={assignee} />
        <Box color="text-body-secondary">Secondary assignee</Box>
        <DisplayEmployeeInformation
          employees={get(assignee, 'secondaryAssignees', []) || []}
          clientId={get(assignee, 'clientId', DEFAULT_BUSINESS_ID)}
        />
      </SpaceBetween>
    </ExpandableSection>
  );
};

AssignmentDetail.propTypes = {
  isPrimaryAssignee: PropTypes.bool,
  assignee: PropTypes.object,
};

export default AssignmentDetail;
