import React from 'react';
import { Box, Button, Modal, SpaceBetween } from '@amzn/awsui-components-react';
import PropTypes from 'prop-types';

const UncheckProjectModal = ({ isVisible, onDismiss, onConfirm, projectName }) => {
  return (
    <Modal
      onDismiss={onDismiss}
      visible={isVisible}
      closeAriaLabel="Close modal"
      size="medium"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onDismiss}>
              Cancel
            </Button>
            <Button variant="normal" onClick={onConfirm}>
              Remove
            </Button>
          </SpaceBetween>
        </Box>
      }
      header={`Remove ${projectName}?`}
    >
      Remove {projectName} from your project selection? The allocation percentage(s) you entered for
      this project will be cleared.
    </Modal>
  );
};

UncheckProjectModal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onDismiss: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  projectName: PropTypes.string.isRequired,
};

export default UncheckProjectModal;
