import { isNumber, isObject, isString } from 'lodash';

export const getSerializedData = data => {
  try {
    return JSON.parse(data);
  } catch (e) {
    return [];
  }
};

export const extractTableDataFromPayload = payload => {
  if (!isObject(payload)) {
    return {};
  }
  const { pageNumber, data, totalResultCount } = payload;
  if (!isNumber(pageNumber) || !isString(data) || !isNumber(totalResultCount)) {
    return {};
  }

  return {
    [pageNumber]: getSerializedData(data),
    totalResultCount,
  };
};
