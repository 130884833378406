import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import authReducer from './authReducers';
import appMetaData from '../../common/components/with_page/redux/reducer';
import currentDashboard from '../../app/landing/common/redux/CurrentDashboard';
import entities from './entities';
import derivedClientIdReducer from './derivedClientIdReducers';
import projectAllocationReducer from '../../app/survey_page/redux/reducers/projectAllocationReducers';
import activityDualAllocationReducer from '../../app/survey_page/redux/reducers/activityDualAllocationReducers';
import copyGridValuesReducer from '../../app/survey_page/redux/reducers/copyGridValuesReducers';
import activityAllocationReducer from '../../app/survey_page/redux/reducers/activityAllocationReducers';
import submitAttestationReducer from '../../app/survey_page/redux/reducers/submitAttestationReducers';

export default history =>
  combineReducers({
    router: connectRouter(history),
    auth: authReducer,
    derivedClientId: derivedClientIdReducer,
    projectAllocation: projectAllocationReducer,
    activityAllocation: activityAllocationReducer,
    activityDualAllocation: activityDualAllocationReducer,
    copyGridValues: copyGridValuesReducer,
    submitAttestation: submitAttestationReducer,
    entities,
    appMetaData,
    currentDashboard,
  });
