import React from 'react';
import { Table as PolarisTable, Box } from '@amzn/awsui-components-react';
import PropTypes from 'prop-types';

const VendorTable = ({ vendorList, headerTitles }) => {
  return (
    <PolarisTable
      columnDefinitions={headerTitles}
      items={vendorList}
      loadingText="Loading resources"
      sortingDisabled
      empty={
        <Box textAlign="center" color="inherit">
          <b>No vendors</b>
          <Box padding={{ bottom: 's' }} variant="p" color="inherit">
            No vendors to display.
          </Box>
        </Box>
      }
    />
  );
};

VendorTable.propTypes = {
  vendorList: PropTypes.array.isRequired,
  headerTitles: PropTypes.array.isRequired,
};
export default VendorTable;
