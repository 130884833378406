import * as React from 'react';
import Modal from '@amzn/awsui-components-react/polaris/modal';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Input,
  Select,
  SpaceBetween,
} from '@amzn/awsui-components-react';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, get, isNull } from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import SearchPopulationDropdown from '../../SearchPopulationDropdown/SearchPopulationDropdown';
import { REASONS, reassignInitialState } from '../constants/reassign_survey_constants';
import { reassignSurveyAction } from '../redux/reassignSurvey';
import {
  getRoutePathWithStudyPeriod,
  ROUTE_PATH,
} from '../../../../../common/constants/route_paths';
import { getStudyPeriod } from '../../../../../common/constants/study_period';

const ReassignModal = ({
  onCancel,
  onConfirm,
  visibility,
  reassignUpdate,
  surveyId,
  data,
  clearReassignUpdate,
  studyPeriod,
  navigateTo,
  surveyType,
  updateSurveyLockData,
  derivedClientId,
}) => {
  const [modalState, setModalState] = useState(reassignInitialState);
  const reassignReasons = get(REASONS, [surveyType]);
  const onCancelHandler = () => {
    onCancel();
    setModalState(reassignInitialState);
  };
  useEffect(() => {
    if (!isNull(data) && get(data, 'status', '') === 200) {
      const dashboardRoute = getRoutePathWithStudyPeriod(ROUTE_PATH.DASHBOARD, studyPeriod);
      navigateTo({ pathname: dashboardRoute, state: { submitted: false } });
      clearReassignUpdate();
    }
    // eslint-disable-next-line
  }, [data]);

  const getReassignmentReason = () => {
    const { isOtherReasonVisible, otherReason } = modalState;
    if (isOtherReasonVisible) return otherReason;
    return get(modalState, 'option.label', '');
  };

  const onConfirmHandler = () => {
    reassignUpdate({
      body: {
        clientId: derivedClientId,
        surveyId,
        reassignmentReason: getReassignmentReason(),
        newSmeRecordId: get(modalState, 'selectedSME.recordId', ''),
        sessionId: updateSurveyLockData.sessionId,
      },
    });
    onConfirm(modalState);
    setModalState(reassignInitialState);
  };
  const isConfirmDisabled = () => {
    if (
      get(modalState, 'checked') &&
      !isEmpty(get(modalState, 'option')) &&
      !isEmpty(get(modalState, 'selectedSME')) &&
      (!get(modalState, 'isOtherReasonVisible', true) || get(modalState, 'otherReason', '') !== '')
    )
      return false;
    return true;
  };
  const onOptionChangeHandler = ({ detail }) => {
    setModalState({
      ...modalState,
      isOtherReasonVisible: get(detail, 'selectedOption.value') === 'Other',
      option: get(detail, 'selectedOption', {}),
    });
  };

  return (
    <Modal
      onDismiss={onCancelHandler}
      visible={visibility}
      closeAriaLabel="Close modal"
      size="medium"
      footer={
        <Box float="right">
          <SpaceBetween direction="horizontal" size="xs">
            <Button variant="link" onClick={onCancelHandler}>
              Cancel
            </Button>
            <Button variant="primary" disabled={isConfirmDisabled()} onClick={onConfirmHandler}>
              Confirm
            </Button>
          </SpaceBetween>
        </Box>
      }
      header="Reassign the survey"
    >
      <SpaceBetween size="m">
        <Alert>Once the survey is reassigned, you will no longer have access to this survey.</Alert>
        <Box>
          Reassign to
          <SearchPopulationDropdown
            state={modalState}
            setState={setModalState}
            placeholder="Enter employee alias"
          />
        </Box>
        <Box>
          Reason of reassigning
          <Select
            selectedOption={get(modalState, 'option', '')}
            onChange={onOptionChangeHandler}
            options={reassignReasons}
            loadingText="Loading employees"
            placeholder="Select a reason"
          />
        </Box>
        {get(modalState, 'isOtherReasonVisible', false) ? (
          <Input
            onChange={({ detail }) => setModalState({ ...modalState, otherReason: detail.value })}
            value={get(modalState, 'otherReason', '')}
            placeholder="Enter your reason"
          />
        ) : (
          <React.Fragment />
        )}
        <Checkbox
          onChange={({ detail }) => setModalState({ ...modalState, checked: detail.checked })}
          checked={get(modalState, 'checked')}
        >
          I confirm to reassign the survey to the selected person.
        </Checkbox>
      </SpaceBetween>
    </Modal>
  );
};

ReassignModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  visibility: PropTypes.bool.isRequired,
  reassignUpdate: PropTypes.func.isRequired,
  surveyId: PropTypes.string.isRequired,
  navigateTo: PropTypes.func.isRequired,
  data: PropTypes.func,
  clearReassignUpdate: PropTypes.func.isRequired,
  studyPeriod: PropTypes.string.isRequired,
  surveyType: PropTypes.string.isRequired,
  updateSurveyLockData: PropTypes.object.isRequired,
  derivedClientId: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  return {
    surveyId: get(state, 'router.location.state.headerDetails.surveyId'),
    data: get(state, 'entities.reassignSurvey.data'),
    studyPeriod: getStudyPeriod(state),
    updateSurveyLockData: get(state, 'entities.updateSurveyLock.data.body', null),
    derivedClientId: get(state, 'derivedClientId.derivedClientId', undefined),
  };
};

const mapDispatchToProps = dispatch => {
  const actions = bindActionCreators(
    {
      reassignUpdate: reassignSurveyAction.BEGIN,
      clearReassignUpdate: reassignSurveyAction.CLEAR,
    },
    dispatch,
  );
  return {
    ...actions,
    navigateTo: payload => dispatch(push(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReassignModal);
