import { get } from 'lodash';
import { StatusIndicator } from '@amzn/awsui-components-react';
import PropTypes from 'prop-types';
import React from 'react';

const ShowReassignmentInfo = ({ assignee }) => {
  return get(assignee, 'isSurveyReassigned', true) || false ? null : (
    <StatusIndicator type="info">
      This survey is reassigned to you by [SME name] on [date], because [reason].{' '}
    </StatusIndicator>
  );
};

ShowReassignmentInfo.propTypes = {
  assignee: PropTypes.object,
};

export default ShowReassignmentInfo;
