export const reassignInitialState = {
  selectedSME: '',
  filterText: '',
  option: '',
  isOtherReasonVisible: false,
  otherReason: '',
  checked: false,
};

export const headerTitles = [
  {
    id: 'supplierName',
    header: 'Supplier name',
    cell: item => item.supplierName || '-',
  },
  {
    id: 'vendorName',
    header: 'Vendor name',
    cell: item => item.vendorName || '-',
  },
  {
    id: 'costCenterNumber',
    header: 'Cost center',
    cell: item => item.costCenterNumber || '-',
  },
  {
    id: 'amount',
    header: 'Amount',
    cell: item => item.amount.toLocaleString('en-US') || '-',
  },
  {
    id: 'accountName',
    header: 'Account name',
    cell: item => item.accountName || '-',
  },
  {
    id: 'accountNumber',
    header: 'Account number',
    cell: item => item.accountNumber || '-',
  },
];
