import { get, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SurveyDetail from '../../../../../common/components/survey_details/SurveyDetailsComponent';
import getSurveyDetailsList from '../../utils/utils';
import Introduction from './Introduction';
import addContextToPayload from '../../../../../common/utils/api_util';
import { SEARCH_COST_CENTERS, VALARI_API } from '../../../../../common/config/api_endpoints';
import { DEFAULT_BUSINESS_ID } from '../../../../../common/config/api_constants';
import { extractTableDataFromPayload } from '../../../../survey_page/utils/redux_payload_utils';
import { getStudyPeriod } from '../../../../../common/constants/study_period';

const PrimaryAssigneeSurveys = ({ surveys, surveyDetails, studyPeriod }) => {
  const primarySurveyIds = get(surveys, 'primaryAssigneeSurveys', []);
  const primarySurveyDetails = getSurveyDetailsList(primarySurveyIds, surveyDetails);
  const [fgbs, setFgbs] = useState('respective FGBS VP');
  useEffect(() => {
    if (!isEmpty(primarySurveyDetails)) {
      const { surveyCostCenter, surveyAmazonOFACostCenterNumber } = primarySurveyDetails[0];
      const request = {
        body: {
          pageNumber: 1,
          pageSize: 1,
          businessId: DEFAULT_BUSINESS_ID,
          query: `CostCenterNumber equals ${surveyCostCenter}
           && AmazonOFACostCenterNumber equals ${surveyAmazonOFACostCenterNumber}`,
        },
      };
      addContextToPayload(VALARI_API, SEARCH_COST_CENTERS, studyPeriod, request).then(res => {
        const parsedResponse = extractTableDataFromPayload(res.body);
        const FGBS = get(parsedResponse, [1, 0, 'fgbsVpName']);
        if (!isEmpty(FGBS)) setFgbs(FGBS);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmpty(primarySurveyDetails)]);
  return (
    <React.Fragment>
      <Introduction fgbs={fgbs} />
      <SurveyDetail assignees={primarySurveyDetails} isPrimaryAssignee />
    </React.Fragment>
  );
};

PrimaryAssigneeSurveys.propTypes = {
  surveys: PropTypes.object.isRequired,
  surveyDetails: PropTypes.object.isRequired,
  studyPeriod: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  studyPeriod: getStudyPeriod(state),
});

export default connect(mapStateToProps)(PrimaryAssigneeSurveys);
