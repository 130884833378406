import React from 'react';
import { get, isNull } from 'lodash';
import PropTypes from 'prop-types';
import { AllocationMatrix } from '../../../common/components/AllocationMatrix';

export function VendorActivityAllocationMatrix({ data }) {
  const activityAllocationData = get(data, 'userResponse.ActivityAllocation.data', {});
  const defaultActivityList = get(data, 'userResponse.Activity.data.defaultItems', []);
  const selectedActivityList = get(data, 'userResponse.Activity.data.selectedActivities', []);
  const totalActivitiesList = [...defaultActivityList, ...selectedActivityList];
  const activityMapping = {};
  const vendorMapping = {};

  totalActivitiesList.forEach(activity => {
    const activityId = get(activity, 'id', '');
    activityMapping[activityId] = get(activity, 'name', '');
  });
  const vendorData = get(data, 'vendorData', []);
  if (!isNull(vendorData)) {
    vendorData.forEach(vendor => {
      const vendorId = get(vendor, 'recordId', '');
      vendorMapping[vendorId] =
        `${get(vendor, 'vendorName', '')}\n${get(vendor, 'costCenterNumber', '')}\n` +
        `$${get(vendor, 'amount', '').toLocaleString('en-US')}`;
    });
  }

  return (
    <AllocationMatrix
      data={activityAllocationData}
      projectActivityNameMapping={activityMapping}
      entityNameMapping={vendorMapping}
    />
  );
}

VendorActivityAllocationMatrix.propTypes = {
  data: PropTypes.object.isRequired,
};

export default VendorActivityAllocationMatrix;
