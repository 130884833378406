import { Auth } from 'aws-amplify';
import { VALARI_API } from './api_endpoints';

export default {
  Auth: {
    mandatorySignIn: true,
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: VALARI_API,
        endpoint: process.env.REACT_APP_API_GATEWAY_ENDPOINT,
        region: process.env.REACT_APP_API_GATEWAY_REGION,
        custom_header: async () => ({
          Authorization: (await Auth.currentSession()).idToken.jwtToken,
        }),
      },
    ],
  },
};
