import { SURVEY_TYPE } from '../../../../../common/constants/surveyType';

const { find, get } = require('lodash');

const ATTESTATIONS = {
  [SURVEY_TYPE.LABOR]: [
    {
      id: 'Attestation1',
      content: 'I am completing the survey for myself.',
    },
    {
      id: 'Attestation2',
      content:
        'I supervised the employees listed in this survey ' +
        'and have direct knowledge of their activities.',
    },
    {
      id: 'Attestation3',
      content:
        'I worked with the employees listed in the survey and ' +
        'have direct knowledge of their activities.',
    },
    {
      id: 'Attestation4',
      content:
        'I reviewed project and/or activity documents and records ' +
        'to obtain direct knowledge of the activities of the employees' +
        ' listed in the survey (please list the documents you reviewed).',
    },
    {
      id: 'Attestation5',
      content:
        'I consulted with the employees listed in this survey and/or c' +
        'olleagues to obtain direct knowledge of their activities (li' +
        'st the names of individuals with whom you consulted with).',
    },
  ],
  [SURVEY_TYPE.CONTRACT]: [
    {
      id: 'Attestation1',
      content:
        'I have first-hand knowledge of the suppliers and contractors listed in this survey.',
    },
    {
      id: 'Attestation2',
      content:
        'I reviewed project and/or activity documents and records ' +
        'to obtain direct knowledge of the supplies and contractors' +
        ' listed in the survey (please list the documents you reviewed).',
    },
    {
      id: 'Attestation3',
      content:
        'I consulted with additional colleagues to obtain direct knowledge of ' +
        'the supplies and contractors listed in this survey (please list the ' +
        'colleagues with whom you consulted).',
    },
  ],
};

export const getAttestationContent = (filterId, surveyType) => {
  return find(get(ATTESTATIONS, [surveyType]), ({ id }) => id === filterId).content;
};

export const getAttestationSlice = (surveyType, startInd, endInd) => {
  return get(ATTESTATIONS, surveyType).slice(startInd, endInd);
};
