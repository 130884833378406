import { initialState } from '../../../constants/steps-config';

const initialStateForReducer = {
  ...initialState.Submit,
};

const submitAttestationReducer = (state = initialStateForReducer, action) => {
  switch (action.type) {
    case 'SET_SUBMIT_ATTESTATION':
      return { ...state, ...action.payload.data };
    default:
      return state;
  }
};

export default submitAttestationReducer;
