import { set } from 'lodash';
import { UPDATE_SURVEY_LOCK } from '../../../../common/config/api_endpoints';
import {
  createAPILifecycleActions,
  createAPILifecycleActionTypes,
} from '../../../../redux/actions/action_utils';
import createAmplifyAPICallEpic from '../../../../redux/epics/utils';

export const UPDATE_SURVEY_LOCK_ACTION_TYPES = createAPILifecycleActionTypes(
  'UPDATE_SURVEY_LOCK_ACTION_TYPES',
);
export const updateSurveyLockAction = createAPILifecycleActions(
  UPDATE_SURVEY_LOCK_ACTION_TYPES.NAME,
);

const initialState = {
  isFetching: false,
  data: null,
  error: null,
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_SURVEY_LOCK_ACTION_TYPES.BEGIN:
      return {
        ...state,
        isFetching: true,
      };
    case UPDATE_SURVEY_LOCK_ACTION_TYPES.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: payload,
        error: null,
      };
    case UPDATE_SURVEY_LOCK_ACTION_TYPES.FAILURE:
      return {
        ...state,
        isFetching: false,
        data: null,
        error: payload,
      };

    case UPDATE_SURVEY_LOCK_ACTION_TYPES.CLEAR:
      return initialState;
    default:
      return state;
  }
};

const payloadGenerator = async ({ originalPayload }) => {
  const newPayload = {};
  set(newPayload, ['body'], originalPayload.body);
  return newPayload;
};

const successCallback = (response, store, action, originalPayload) => {
  const { onSuccess } = originalPayload;
  set(response, ['body', 'surveyId'], originalPayload.body.surveyId);
  const actionsOut = [updateSurveyLockAction.SUCCESS(response)];
  if (response.status === 200) {
    onSuccess();
  }
  return actionsOut;
};

export const updateSurveyLockEpic = createAmplifyAPICallEpic({
  actionType: UPDATE_SURVEY_LOCK_ACTION_TYPES.BEGIN,
  api: UPDATE_SURVEY_LOCK,
  payloadGenerator,
  successCallback,
  errorCallback: () => [updateSurveyLockAction.FAILURE()],
});
