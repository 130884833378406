import { get, map } from 'lodash';

export const SURVEY_TYPE = {
  LABOR: 'Labor',
  LABOR_DUAL: 'LaborDual',
  CONTRACT: 'Contract',
};

export const getSurveyType = (state, surveyId) => {
  const surveyType = get(state, `entities.surveyDetails.data.${surveyId}.surveyType`);
  switch (surveyType) {
    case 'Contract':
      return SURVEY_TYPE.CONTRACT;
    case 'LaborDual':
      return SURVEY_TYPE.LABOR_DUAL;
    case 'Labor':
    default:
      return SURVEY_TYPE.LABOR;
  }
};

export const SURVEY_UI_TEMPLATE = {
  RadicalLabor: 'Radical$$Labor',
  RadicalUKLabor: 'RadicalUK$$Labor',
  RadicalUKADCLLabor: 'RadicalUKADCL$$Labor',
  RadicalUKADCSLabor: 'RadicalUKADCS$$Labor',
  RadicalUKETLabor: 'RadicalUKET$$Labor',
  RadicalUKIMDBUKLabor: 'RadicalUKIMDBUK$$Labor',
  RadicalUKVEEQOLabor: 'RadicalUKVEEQO$$Labor',
  RadicalContract: 'Radical$$Contract',
};

export const getSurveyUiTemplate = (derivedClientId, surveyType) => {
  return SURVEY_UI_TEMPLATE[derivedClientId + surveyType];
};

export const getEntityDetailsKey = (surveyType, entityDetail) => {
  switch (surveyType) {
    case SURVEY_TYPE.LABOR:
    case SURVEY_TYPE.LABOR_DUAL:
    default:
      return `${entityDetail.name},${entityDetail.jobTitle}`;
    case SURVEY_TYPE.CONTRACT:
      return `${entityDetail.name},${entityDetail.costCenterNumber},${entityDetail.amount}`;
  }
};

export const getSecondaryTitle = (surveyType, subordinate) => {
  switch (surveyType) {
    case SURVEY_TYPE.LABOR:
    case SURVEY_TYPE.LABOR_DUAL:
    default:
      return subordinate.jobTitle;
    case SURVEY_TYPE.CONTRACT:
      return subordinate.costCenterNumber;
  }
};

export const getSurveySteps = (surveyUiTemplate, defaultSteps) => {
  return defaultSteps.filter(step => {
    if (step.applicability.length === 0 || step.applicability.includes(surveyUiTemplate)) {
      return true;
    }
    return false;
  });
};

export const getAllocationErrorMessage = surveyType => {
  switch (surveyType) {
    case SURVEY_TYPE.LABOR:
    case SURVEY_TYPE.LABOR_DUAL:
    default:
      return (
        'Employee(s) does not match with employee(s) listed on Employee page. ' +
        'Download the matrix again. If you want to add or edit employee, contact ukrdtax@amazon.com'
      );
    case SURVEY_TYPE.CONTRACT:
      return (
        'Supplier(s) does not not match with supplier(s) listed on Supplier page. ' +
        'Download the matrix again. If you want to add or edit supplier, ' +
        'contact ukrdtax@amazon.com '
      );
  }
};

export const getSurveyEntity = surveyType => {
  switch (surveyType) {
    case SURVEY_TYPE.LABOR:
    case SURVEY_TYPE.LABOR_DUAL:
    default:
      return 'employee';
    case SURVEY_TYPE.CONTRACT:
      return 'supplier';
  }
};
export const getEntityData = (pageContents, surveyType) => {
  let entityData;
  switch (surveyType) {
    case SURVEY_TYPE.CONTRACT:
      entityData = map(get(pageContents, 'Vendor.vendorList', []), vendor => {
        return {
          id: vendor.recordId,
          costCenterNumber: vendor.costCenterNumber,
          amount: vendor.amount,
          name: vendor.supplierName,
        };
      });
      break;
    case SURVEY_TYPE.LABOR:
    case SURVEY_TYPE.LABOR_DUAL:
    default:
      entityData = map(get(pageContents, 'Employee.employeeList', []), employee => {
        return {
          id: employee.recordId,
          employeeAlias: employee.employeeAlias,
          name: employee.formattedEmployeeName ?? employee.employeeName,
          jobTitle: employee.jobTitle,
          empId: employee.employeeId,
        };
      });
      break;
  }
  return entityData;
};

export const getEntityListPath = surveyType => {
  switch (surveyType) {
    case SURVEY_TYPE.CONTRACT:
      return 'Vendor.vendorList';
    case SURVEY_TYPE.LABOR:
    case SURVEY_TYPE.LABOR_DUAL:
    default:
      return 'Employee.employeeList';
  }
};

export const getSubmitAttestationConfig = surveyType => {
  switch (surveyType) {
    case SURVEY_TYPE.LABOR:
    case SURVEY_TYPE.LABOR_DUAL:
      return {
        attestationEmployeeListId: 'Attestation5',
        attestationInfoId: 'Attestation4',
        defaultAttestationEndIndex: 4,
        consultAttestationEndIndex: 5,
      };
    case SURVEY_TYPE.CONTRACT:
    default:
      return {
        attestationEmployeeListId: 'Attestation3',
        attestationInfoId: 'Attestation2',
        defaultAttestationEndIndex: 2,
        consultAttestationEndIndex: 3,
      };
  }
};
