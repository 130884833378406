import { API, Auth } from 'aws-amplify';
import { get, set } from 'lodash';
import { DEFAULT_BUSINESS_ID, DEFAULT_COUNTRY } from '../config/api_constants';

async function addContextToPayload(API_G, apiEndpoint, studyPeriod, payload) {
  let newPayload = payload;
  if (!newPayload) {
    newPayload = {};
  }
  const accessToken = (await Auth.currentSession()).getAccessToken().getJwtToken();
  set(newPayload, ['body', 'accessToken'], accessToken);
  set(newPayload, ['body', 'clientId'], get(newPayload, 'body.clientId', DEFAULT_BUSINESS_ID));
  set(newPayload, ['body', 'studyPeriod'], studyPeriod);
  set(newPayload, ['body', 'country'], DEFAULT_COUNTRY);
  return API.post(API_G, apiEndpoint, newPayload);
}
export default addContextToPayload;
