import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from '@amzn/awsui-components-react';
import './WizardDescription.css';

const WizardDescription = ({ stepDescription, stepSummary, stepSummaryHeading }) => {
  return (
    <div>
      <br />
      <div className="wizard-description">
        <div className="description-text">{stepDescription}</div>
        <div className="info-box">
          <Alert statusIconAriaLabel="Info" header={stepSummaryHeading}>
            {stepSummary}
          </Alert>
        </div>
      </div>
      <br />
    </div>
  );
};

WizardDescription.propTypes = {
  stepDescription: PropTypes.node.isRequired,
  stepSummary: PropTypes.string.isRequired,
  stepSummaryHeading: PropTypes.string.isRequired,
};

export default WizardDescription;
